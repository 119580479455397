import * as yup  from "yup";

const passwordRules = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

export const loginSchemas = yup.object().shape({
    email : yup.string().email("Please enter a valid email").required(),
    password : yup
        .string()
        .required(),
    })
        
export const registerSchemas = yup.object().shape({
    email : yup.string().email("Please enter a valid email").required(),
    password : yup
        .string()
        .min(8)
        .matches(passwordRules,
            {message : "Please create a stronger password"})
        .required(),
    confirmPassword : yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Required")    
})

export const transferSchemas = yup.object().shape({
    tokenType: yup.string().required("This field is required"),
    creditAccountNumber: yup.string().required("This field is required"),
    creditAccountName: yup.string().required(),
    bankName: yup.string().required("This field is required"),
    transferAmount: yup.string().required('This field is reqquired')
})

export const tokenTransferSchemas = yup.object().shape({
    tokenType: yup.string().required('This field is required'),
    amount: yup.string().required('This field is required')
})
export const bvnSchemas = yup.object().shape({
    bvn : yup.string().min(11).max(11).required("Required")
})
export const otpSchemas = yup.object().shape({
    otp : yup.string().required("Required")
})
export const newsletterSchemas = yup.object().shape({
    email : yup.string().email("Please enter a valid email").required()
})
export const waitlistSchema = yup.object().shape({
    email: yup.string().email('Enter a valid E-mail Address').required('Required'),
    firstName: yup.string().required('Required'),
    lastName: yup.string().required('Required'),
    wallet: yup.string().required('Required')
})
export const resetpasswordSchema = yup.object().shape({
    password: yup.string().min(8).matches(passwordRules, { message: 'Please create a stronger password' }).required('Required'),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match').required('Required')
})