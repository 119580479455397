import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../assets/styles/Profile.css";
import General from '../components/General';
import Settings from '../components/Settings';

const Profile = ()=>{
    const sidenav = useSelector(state=>state.SideNavReducer.sidenav)
    const dispatch = useDispatch();
    const [key, setKey] = useState('general')
    const user = useSelector(state=>state.UserReducer.userDetails)

    useEffect(()=>{
        document.title = 'CashFlakes | Profile'
        dispatch({type: 'setActiveRoute', payload: 'Profile'})
    }, [dispatch])

    return (
        <div className="mt-5 pt-1">
            {/* Profile Tabs */}
            <Tabs id='profile-tab' variant='pills' className='my-4' activeKey={key} onSelect={(k)=>setKey(k)} >
                <Tab eventKey="general" title="General">
                    <hr />
                    <General />
                </Tab>
                <Tab eventKey="settings" title="Settings">
                    <hr />
                    <Settings />
                </Tab>
            </Tabs>

        </div>
    )
}

export default Profile