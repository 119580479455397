import React, { useState } from "react";
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import CashFlakesLogo from "../assets/logo1.png";
import axios from 'axios';
import { resetpasswordSchema } from "../schemas";
import { useSelector } from "react-redux";

const ResetPassword = ()=>{
    const api = useSelector(state=>state.ApiReducer.uri)
    const { otp } = useParams()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [passwordType, setPasswordType] = useState('password')
    const { handleChange, handleBlur, handleSubmit, values, touched, errors } = useFormik({
        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: resetpasswordSchema,
        onSubmit: (values)=>{
            const payload = {...values, otp}
            setIsLoading(true)
            axios.post(
                `${api}auth/reset_password`, 
                payload,
                {headers:{
                    'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                }}
                ).then(res=>{
                    setIsLoading(false)
                    console.log(res.data)
                }).catch(err=>{
                    setIsLoading(false)
                    console.log(err)
                })
        }
    })
    return(
        <div>
            <div className='d-flex justify-content-center mt-0 mt-md-5 px-2 px-md-4 px-lg-0'>
                <div className='col-lg-5 col-md-8'>
                <div className='px-0 py-5'>
                    <div className='d-flex justify-content-center pb-4'>
                        <img alt='logo' src={CashFlakesLogo} width='40px' height='40px' className='my-0' /> <span className='text-capitalize font-weight-bolder h6 mt-2'><b>CashFlakes</b></span>
                    </div>

                    <h3 className='text-center form-titles fw-bolder'>Reset Password</h3>
                    <p className='text-center text-secondary px-0 px-md-5'> Enter your new password to reset your account </p>
                    
                    <form className='px-0 px-md-5' onSubmit={handleSubmit}>
                        {
                            error !== ''
                            &&
                            <div className='alert alert-danger'>
                                <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error} </span>
                            </div>
                        }                        
                            <div className='form-group mt-2'>
                                <label>Enter New Password</label>
                                    <div className='input-group'>                                
                                        <input className='form-control py-3 border-end-0'
                                        placeholder='Enter your Password' 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                        name='password'
                                        value={values.password}
                                        type={passwordType} 
                                        />
                                        <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')}>
                                            <i className={passwordType === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                        </span>
                                    </div>
                                    {errors.password && touched.password ? <div className='text-danger'>{errors.password}</div> : " "}
                            </div>
                            <div className='form-group mt-2'>
                                <label>Confirm New Password</label>
                                    <div className='input-group'>                                
                                        <input className='form-control py-3 border-end-0'
                                        placeholder='Enter your Password' 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                        name='confirm_password'
                                        value={values.confirm_password}
                                        type={passwordType} 
                                        />
                                        <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')}>
                                            <i className={passwordType === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                        </span>
                                    </div>
                                    {errors.confirm_password && touched.confirm_password ? <div className='text-danger'>{errors.confirm_password}</div> : " "}
                            </div>
                     <div className='d-grid'>
                        
                        <div className='d-grid mt-3'>
                            <button type='submit' className={isLoading ? 'disabled btn py-2 text-primary my-2' : 'btn theme-bg-btn font-weight-bold btn-block py-2 my-2'}>
                                        {
                                        isLoading
                                        ?
                                        'Please wait...'
                                        :
                                        'Reset Password'
                                        }
                            </button>
                        </div>
                        </div>
                        <p className='py-2 h6 text-center text-secondary'>Remember Password? <span className='theme-color cursor-pointer' onClick={()=>navigate('/auth/login')} >Sign in</span></p>
                    </form>
                </div>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword