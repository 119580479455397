import React, { useEffect } from "react";
import Nav from "../components/Nav";
import FrequentlyAsked from "../components/FrequentlyAsked";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import '../assets/styles/About.css'
import "aos/dist/aos.css";
import Aos from "aos";

const About = ()=>{
    useEffect(()=>{
        document.title = 'CashFlakes | About'
        Aos.init({duration: 2000})
    })
    return (
        <div>
            <Nav />
            <section className="about mt-5 pt-5 mx-auto text-justify animate__animated animate__fadeIn animate__slower">
                <p className="text-center fs-1 fw-bold text-dark my-2 text-justify" data-aos='flip-up'>Our Mission  is to bring Web3 to the local community</p>
                <p className="fs-4 fw-light text-muted px-4 text-justify" data-aos='flip-up'>
                    Managing your cryptocurrency assets becomes easier with us. You can view your transaction history, track your portfolio, and at the same time get notified of every transaction and change in the value of your digital currencies.
                </p>
                <p className="fs-4 fw-light text-muted px-4 text-justify" data-aos='flip-up'>
                    We have done a hard job. We have catered for compatibility and acceptance. With Cashflakes, you can pay for your goods and services, Settle your Bill, purchase     airtime and data, and make utility payments all using your crypto assets.
                </p>
                <p className="fs-4 fw-light text-muted px-4 text-justify" data-aos='flip-up'>
                    CashFlakes stands out by enabling users to transact directly from their wallets with vendors. This empowers users with a straightforward and efficient way to utilise their crypto assets for real-world purchases
                </p>
                {/* <video src="" loop={true}></video> */}
                <div className="core-values my-2">
                    <p className="text-center fs-4 fw-bold text-core-values pt-4">Our Core Values</p>
                    <p className="text-center fs-6 fw-bold text-core-values">
                        At CashFlakes, our values are more than words; they're the pillars that guide us forward!
                    </p>
                    <div className="vision mx-4 p-4 my-4 bg-vision" data-aos='fade-up'>
                        <p className="fs-4 fw-bold">
                            <img src={require('../assets/images/vision.png')} className="img-fluid" /> Vision
                        </p>
                        <p className="fs-5 fw-lighter">
                            Imagine a world where crypto isn't just a buzzword but a currency that powers your day-to-day transactions. Our vision is to make this a reality—to empower individuals worldwide with the freedom to use crypto for any financial interaction, whether it's paying a bill or jetting off on a dream vacation. With CashFlakes, your financial freedom is within your reach.
                        </p>
                    </div>
                    <div className="mission mx-4 p-4 my-4 bg-mission" data-aos='fade-up'>
                        <p className="fs-4 fw-bold">
                            <img src={require('../assets/images/mission.png')} className="img-fluid" /> Mission
                        </p>
                        <p className="fs-5 fw-light">
                            We're committed to bringing Web3 to local communities. Our mission is to provide innovative financial solutions that bridge the gap between the digital and real-world economies. We envision a world where blockchain technology enriches lives at the grassroots level putting your financial freedom within your reach
                        </p>
                    </div>
                </div>
                <div className="row w-100 mx-auto px-md-4 mb-5">
                    <div className="col-md-6" data-aos='fade-up'>
                        <h5 className="fw-bold fs-4">Frequently Asked Questions.</h5>
                        <p className="text-secondary my-0 fs-6 fw-bold">
                            Haven't found what you're looking for?
                        </p>
                        <p className="text-secondary my-0 fw-bold">
                        {" "}
                        Try our <span className="text-primary fw-bold">
                            Help Center
                        </span>{" "}
                        - We're here to help
                        </p>
                    </div>
                    <div className="col-md-6">
                        <FrequentlyAsked />
                    </div>
                </div>
                <Newsletter />
                <Footer />
            </section>
        </div>
    )
}

export default About