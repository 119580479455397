import React, { Fragment} from "react";

const JoinCommunity = (props)=>{
    return (
        <Fragment>
            <div className="modal fade" id="communityModal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content px-5 py-3">
                        <p className="text-center fw-bold fs-4 cashflakes-red border-bottom">
                            Join Our Community
                        </p>
                        <div className="py-3">
                            <div className="row w-100">                                
                                <div className="col-md-3 col-6">
                                    <a className="text-reset px-2 text-center" href="https://t.me/cashflakes" target="_blank">
                                        <i className="cashflakes-blue fa fa-telegram fa-3x text-center"></i>
                                    </a>
                                    <p className="fs-5 text-center">Telegram</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <a className="text-reset px-2" href="https://twitter.com/Cashflakes_" target="_blank">
                                        <i className="cashflakes-blue fa fa-twitter fa-3x"></i>
                                    </a>
                                    <p className="fs-5">Twitter</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <a className="text-reset px-2" href="https://Instagram.com/cashflakes_" target="_blank">
                                        <i className="cashflakes-blue fa fa-instagram fa-3x"></i>
                                    </a>
                                    <p className="fs-5">Instagram</p>
                                </div>
                                <div className="col-md-3 col-6">
                                    <a className="text-reset px-2" href="https://discord.gg/2TfweBBF" target="_blank">
                                        <i className="cashflakes-blue fa fa-discord fa-3x"></i>
                                    </a>
                                    <p className="fs-5">Discord</p>
                                </div>
                            </div>
                        </div>
                            <a href="mailto:support@cashflakes.com" className="text-decoration-none cashflakes-red text-center">
                                support@cashflakes.com
                            </a>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default JoinCommunity