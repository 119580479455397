import React from 'react';

const Swap = ()=>{
    return (
        <div>
            <p>Swap</p>
        </div>
    )
}

export default Swap