import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import ProfileAvatar from "../assets/images/56.svg";


const DashboardNavbar = (props) => {
    const { text } = props
    // const sidenav = useSelector((state) => state.SideNavReducer.sidenav);
    // const dispatch = useDispatch();
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    useEffect(()=>{
      console.log(user)
    })

  return (
    <div className="dashboard-mainnav">
       <p className="text-dark h6">{text}</p>
      <div className="profileSection">
        {
          user.selfie
          ?
          <img
            className="profileAvatar rounded-circle me-2"
            src={user.selfie}
            alt="Profile Avatar"
            width={'50px'}
            // height={'50px'}
          />
          :
          <img
            className="profileAvatar rounded-circle me-2"
            src={ProfileAvatar}
            alt="Profile Avatar"            
          />
        }
        <div className="profileDetails">
          <h3 className="profileName">Hello {user !== null ? user.name : ''}</h3>
          <p className="profileId">User Id:{user !== null ? user.id.slice(0, 10)+'...' : ''}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbar;
