import React, { useEffect } from "react";
import Mockup from "../assets/images/mockup-1.png";
import '../assets/styles/MockUp.css'
import AOS from "aos";
import "aos/dist/aos.css";

const MockUp = () => {
  useEffect(()=>{
    AOS.init({duration: 2000})
  }, [])
  return (
    <>
    {/* data-aos='fade-up' */}
      <section className="mx-md-4">
        <div className="mock-up container-fluid animation" data-aos="fade-up">
          <div className="row align-items-center justify-content-between text-white w-100">
            <div className="col-md-5 p-5 text-start" data-aos="zoom-in-right">
              <h4 className="fw-bold sectionHeading">
                Enjoy more features using the mobile App
              </h4>
              <p className="pt-3 section-medium-small">
                Enjoy exciting features such as Borderless transfers, Utility
                payments, Token swap and more on the mobile Application
              </p>

              {/* <div className="d-flex animate__animated animate__backInDown animate__slower flex-md-row flex-column">
                <button className="btn btn-default bg-dark text-white mt-2 px-3 py-2">
                  <i className="fa-brands fa-google-play"></i> Google Play
                </button>
                <button className="btn btn-default bg-dark text-white ms-md-3 mt-2 px-3 py-2">
                  <i className="fa-brands fa-apple"></i> App Store
                </button>
              </div> */}
              <div className="animate__animated animate__backInDown animate__slower">
                <button className="btn btn-primary btn-coming-soon">Coming Soon</button>
              </div>
            </div>
            <div className="col-md-7 p-0 d-none d-md-block" data-aos="zoom-out-down">
              <img
                src={Mockup}
                alt=""
                className="img-fluid float-right"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MockUp;
