const initState = {
    uri: 'https://cashflakes-users-api.onrender.com/api/',
    banks: 'https://nigerianbanks.xyz/',
    paymentUri: 'https://cashflakes-payment-api.onrender.com/',
    tokenUri: 'wss://cashflakes-wallets-api.onrender.com/',
    baseUrl: 'https://services2.vpay.africa/'
}

const ApiReducer = (state = initState, action)=>{
    return state
}

export default ApiReducer