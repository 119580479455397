import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import CashFlakesLogo from "../assets/logo1.png";
import * as Yup from 'yup';
import axios from 'axios';
import { useSelector } from 'react-redux';

const ForgotPassword = () => {
  useEffect(()=>{
    document.title = 'CashFlakes | Forgot Password'
  }, [])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState('')
  const navigate = useNavigate()
  const uri = useSelector(state=>state.ApiReducer.uri)

  const { values, handleBlur, handleChange, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid E-Mail Address').required()
    }),
    onSubmit: (values)=>{
      setIsLoading(true)
      setError('')
      setResponse('')
      axios.post(`${uri}auth/forgot_password_web`, 
        values,
        {headers:{
          'authorization': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
          'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
          'Content-type': 'application/json',
          'Accept': 'application/json'
      }}).then((res)=>{
          if(res.data.data.statusCode !== 200){
            setError(res.data.data.error)
            setIsLoading(false)
          }else{
            setIsLoading(false)
            setResponse('Password Reset link sent')
            console.log(res.data)
          }
        }).catch((err)=>{
          console.log(err)
          setError(err.message || err.response.data.data.error)
          setIsLoading(false)
        })
    }
  })
  return (
    <div>
      <div className='d-flex justify-content-center mt-0 mt-md-5 px-2 px-md-4 px-lg-0'>
        <div className='col-lg-5 col-md-8'>
          <div className='px-0 py-5'>
              <div className='d-flex justify-content-center pb-4'>
                <img alt='logo' src={CashFlakesLogo} width='40px' height='40px' className='my-0' /> <span className='text-capitalize font-weight-bolder h6 mt-2'><b>CashFlakes</b></span>
              </div>

              <h3 className='text-center form-titles fw-bolder'>Forgot Password</h3>
              <p className='text-center text-secondary px-0 px-md-5'> Enter your registered Email Address and you would receive instructions to reset your password </p>
            
              <form className='px-0 px-md-5' onSubmit={handleSubmit}>
                  {
                      error !== ''
                      &&
                      <div className='alert alert-danger'>
                          <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error} </span>
                      </div>
                  }
                  {
                    response !== ''
                    &&
                    <div className='alert alert-success'>
                      <span><strong><i className='fa fa-check'></i></strong> {response} </span>
                    </div>
                  }
                    <div className='form-group mt-3'>
                      <label>Email Address</label>
                      <input className='form-control py-3'
                        placeholder='Enter your Email Address' 
                        name='email' 
                        onChange={handleChange}
                        onBlur={handleBlur} 
                        value={values.email} 
                          /> 
                      {touched.email && <div className='text-danger'>{ errors.email}</div>} 
                  </div> <div className='d-grid'>
                  
                  <div className='d-grid'>
                      <button type='submit' className={isLoading ? 'disabled btn py-2 text-primary my-2' : 'btn theme-bg-btn font-weight-bold btn-block py-2 my-2'}>
                                {
                                  isLoading
                                  ?
                                  'Please wait...'
                                  :
                                  'Submit'
                                }
                      </button>
                  </div>
                  </div>
                  <p className='py-2 h6 text-center text-secondary'>Remember Password? <span className='theme-color cursor-pointer' onClick={()=>navigate('/auth/login')} >Sign in</span></p>
              </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword