import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ArrowRight from "../assets/images/Group 21.png";
import ArrowLeft from "../assets/images/Group 22.png";
import Solana from "../assets/images/Solana.png";
import BitcoinIcon from "../assets/images/bitcoin.svg";
import EthereumIcon from "../assets/images/EthereumIcon.svg";

const MultichainSlider = () => {
  const dispatch = useDispatch();
  // const walletArr = useSelector(state=>state.UserReducer.wallets)
  const walletArr = [
    {BTC: '4000'},
    {ETH: '4000'},
    {CFT: '4000'},
    {SOL: '4000'},
    {BNB: '4000'}
  ]

  const slider = useSelector((state) => state.SideNavReducer.slider);
  // Set the individual chain wallet in the store for dynamism of the wallet modal
  const chainWallet = (token)=>{
    dispatch({type: 'setChain', payload: token})
  }

  return (
    <div className="multichain-wallet mt-5">
      {/* ARROWS  */}
      <div className="multichain-wallet-wrap d-flex justify-content-between">
        <h5 className="multichain-walletTitle">Multichain - wallet</h5>

        <div className="multichain-wallet-slide-controls">
          <img
            onClick={() => dispatch({ type: "setSlider", payload: false })}
            className="pointer"
            src={ArrowLeft}
            alt="arrow"
          />
          <img
            onClick={() => dispatch({ type: "setSlider", payload: true })}
            className="ms-2 pointer"
            src={ArrowRight}
            alt="arrow"
          />
        </div>
      </div>

      {/* slider  */}
      <div
        className={slider ? "multichain-slider-active" : "multichain-slider"}
      >
        {/* All Tokens */}
        {
          walletArr.map((each, i)=>(
            <div key={i}>
              <div onClick={()=>chainWallet(each)} className="mc-slider-subs d-flex justify-content-between align-items-center px-3"
                  data-target="#btcModal"
                  data-toggle="modal"
              >
                <div className="multichain-icon-wrap d-flex align-items-center justify-content-between ">
                  <img src={EthereumIcon} alt="Solana icon" className="w-50 h-50" />

                  <div className="multichain-icon-title ps-3 ">
                    <h5 className="multichain-icon-title-text pt-3">{Object.keys(each)[0]}</h5>
                    <p className="multichain-icon-title-text-rate">+63%</p>
                  </div>
                </div>

                <div className="mulitichain-wallet-prices">
                  <h4 className="multichain-wallet-price-crpt">0.04ETH</h4>
                  <h4 className="multichain-wallet-price-localCurrency">
                    {Object.values(each)[0]} NGN
                  </h4>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      {/* recent transacctions */}
    </div>
  );
};

export default MultichainSlider;
