import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerSchemas } from '../schemas';
import CashFlakesLogo from "../assets/logo1.png";
import { useSelector } from 'react-redux';

const Register = () => {
    
    const [passwordType, setPasswordType] = useState('text')
    const [confPwordType, setConfPwordType] = useState('text')
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const uri = useSelector(state=>state.ApiReducer.uri)

    useEffect(()=>{
        document.title = 'CashFlakes | Register'// console.log(JSON.parse(sessionStorage.getItem('bvnAuth')))
    }, [])

    const onSubmit = async (values, actions) => {
        console.log(values)
        setError('')
        setIsLoading(true)
        await axios({
            method: "post", 
            url: `${uri}register`, 
            data: values,
            headers:{
                'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res=>{
            console.log(res.data.data);
            if(res.data.data.statusCode === 200 && res.data.data.success){
                let user = res.data.data
                user.email = values.email
                sessionStorage.setItem('newUser', JSON.stringify(user))
                navigate('/verification/otp')
            }else{
                setError(res.data.data || res.data.data.message)
                setIsLoading(false)
            }
        }).catch(err=>{
            console.log(err)
            setError('Internal Server Error');
            setIsLoading(false)
        })
        
    }
    
    const {  values, handleBlur, handleChange, errors, touched, handleSubmit, isSubmitting } = useFormik({
        initialValues: {    
            email: '',
            password: '',
            confirmPassword: '',
            firstName: '',
            lastName: '',
            phone: ''
        },
        validationSchema: registerSchemas, 
        onSubmit
    })
    return (
        <div>
            <a href='/' className='navbar-brand text-dark font-weight-bold text-lowercase h3 animate__animated animate__slideInLeft animate__slow px-md-5 px-1 fs-9 pt-3' >
                <img alt='logo' src={CashFlakesLogo} width='30px' className='my-0' /> <span className='text-capitalize mt-5'>CashFlakes</span>
            </a>

            <div className='px-0 pt-2'>
                {/* <div className='d-block d-md-none d-flex justify-content-center'>
                    <img alt='logo' src={require('../assets/logo1.png')} width='150px' height='150px' />
                </div> */}

                <h3 className='text-center form-titles fw-bolder'>Register</h3>
                <p className='text-center text-secondary'> Start making payment for anything with CashFlakes </p>
             
                <form className='px-0 px-md-5' onSubmit={handleSubmit}>
                    {
                        error !== '' && !isLoading
                        &&
                        <div className='alert alert-danger'>
                            <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error} </span>
                        </div>
                    }
                    <div className='row'>
                        <div className='form-group col-md-6 mt-3'>
                            <label>First Name</label>
                            <input className='form-control py-3'
                            placeholder='First Name' 
                            name='firstName' 
                            onChange={handleChange}
                            onBlur={handleBlur} 
                            value={values.firstName} 
                            /> 
                            {touched.firstName && <div className='text-danger'>{ errors.firstName}</div>} 
                        </div> 
                        <div className='form-group col-md-6 mt-3'>
                            <label>Last Name</label>
                            <input className='form-control py-3'
                            placeholder='Last Name' 
                            name='lastName' 
                            onChange={handleChange}
                            onBlur={handleBlur} 
                            value={values.lastName} 
                            /> 
                            {touched.lastName && <div className='text-danger'>{ errors.lastName}</div>} 
                        </div>
                    </div>
                     <div className='form-group mt-3'>
                        <label>Email Address</label>
                        <input className='form-control py-3'
                         placeholder='Email' 
                         name='email' 
                         onChange={handleChange}
                          onBlur={handleBlur} 
                          value={values.email} 
                           /> 
                        {touched.email && <div className='text-danger'>{ errors.email}</div>} 
                    </div>
                    <div className='form-group mt-3'>
                        <label>Phone Number</label>
                        <input className='form-control py-3'
                         placeholder='Phone Number' 
                         name='phone' 
                         onChange={handleChange}
                          onBlur={handleBlur} 
                          value={values.phone} 
                           /> 
                        {touched.phone && <div className='text-danger'>{ errors.phone}</div>} 
                    </div> <div className='d-grid'>
                    <div className='row'>
                        <div className='form-group col-md-6 mt-3'>
                            <label>Password</label>
                            <div className='input-group'>                                
                                <input className='form-control py-3 border-end-0'
                                placeholder='Password' 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                name='password'
                                value={values.password}
                                type={passwordType} 
                                />
                                <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')}>
                                    <i className={passwordType === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                </span>
                            </div> 
                            { touched.password && <div className='text-danger'>{ errors.password}</div>}  
                        </div>
                        <div className='form-group col-md-6 my-3'>
                            <div>Confirm Password</div>
                                <div className='input-group'>                                
                                    <input className='form-control py-3 border-end-0'
                                    placeholder='Enter your Password' 
                                    onChange={handleChange} 
                                    onBlur={handleBlur} 
                                    name='confirmPassword'
                                    value={values.confirmPassword}
                                    type={confPwordType} 
                                    />
                                    <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> confPwordType === 'text' ? setConfPwordType('password') : setConfPwordType('text')}>
                                        <i className={confPwordType === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                    </span>
                                </div>
                            { touched.confirmPassword && <div className='text-danger' values={values.confirmPassword}>{ errors.confirmPassword}</div>} 
                        </div>
                    </div>                
                    <div className='d-grid'>
                       <button type='submit' className={isLoading ? 'disabled btn py-2 text-primary' : 'btn theme-bg-btn font-weight-bold btn-block py-2'}>
                                  {
                                    isLoading
                                    ?
                                    'Please wait...'
                                    :
                                    'Register'
                                  }
                        </button>
                    </div>
                    </div>
                    <p className='py-2 h6 text-center text-secondary'>Already have an account? <span className='theme-color cursor-pointer' onClick={()=>navigate('/auth/login')} >Sign in</span></p>
                </form>
            </div>
        </div>
    );
};

export default Register;