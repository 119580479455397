import React, { useState } from 'react';
import { QRCode } from "react-qrcode-logo";
import CashFlakesLogo from "../assets/logo1.png";
import { useSelector } from 'react-redux';

const Receive = ()=>{
    const user = useSelector((state) => state.UserReducer.userDetails);
    const addr_length = user.accounts.length !== 0 ? user.accounts[0].address.length : 0
    const [btnText, setBtnText] = useState('Copy')

    const copyKey = ()=>{
      navigator.clipboard.writeText(user.accounts[0].address)
      setBtnText('Copied')
    }

    return (
        <div>
            <div className='row'>
                  <div className='col-12'>
                    {
                      user.accounts.length !== 0
                      ?
                      <>                        
                        <div className="d-flex justify-content-center pt-4">
                          <QRCode qrStyle="squares" size={250} value={user.accounts[0].address} logoImage={CashFlakesLogo} />
                        </div>
                        <p className="text-center">
                          <span className='bg-light px-1 py-1'>{user.accounts[0].address.slice(0, 6) + '...' + user.accounts[0].address.slice(addr_length-5, addr_length)}</span>
                          <span onClick={copyKey} className='btn btn-light btn-sm mx-2 py-0 px-0'>{btnText} <i className='fa fa-copy'></i> </span>
                        </p>
                      </>
                      :
                      <>
                        <p className='text-muted h2'>Unable to generate QR Code at the moment</p>
                      </>
                    }
                    <div className='d-flex justify-content-end'>
                      <button className='btn btn-primary px-5 py-2'>Share</button>
                    </div>
                  </div>
                </div>
        </div>
    )
}

export default Receive