import React, { useEffect, useState } from "react";
import Arrow1 from "../assets/images/arrow1.svg";
import Arrow2 from "../assets/images/arrow2.svg";
import Ethereum from "../assets/images/Ethereum.svg";
import { useDispatch } from "react-redux";


const AvailableBalance = () => {
  const dispatch = useDispatch()
  const [hideBalance, setHideBalance] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  useEffect(()=>{
    console.log(user)
  }, [])

  return (
    <div className="balance-section">
      <h4 className="bs-welcomeText text-secondary">
        Welcome {user !== null && user.name + ' ' + user.surname}
      </h4>
      <div className="row w-100 mx-auto">
        <div className="bs-availBal col-md-5 mx-auto mx-md-2">
          <div className="availBalance">
            <p className="availBalance-heading">Available Balance</p>
            <svg
              onClick={() => setHideBalance(!hideBalance)}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.00024 3L6.5894 6.58916M21.0002 21L17.4114 17.4112M13.8751 18.8246C13.268 18.9398 12.6414 19 12.0007 19C7.52305 19 3.73275 16.0571 2.4585 12C2.80539 10.8955 3.33875 9.87361 4.02168 8.97118M9.87892 9.87868C10.4218 9.33579 11.1718 9 12.0002 9C13.6571 9 15.0002 10.3431 15.0002 12C15.0002 12.8284 14.6645 13.5784 14.1216 14.1213M9.87892 9.87868L14.1216 14.1213M9.87892 9.87868L6.5894 6.58916M14.1216 14.1213L6.5894 6.58916M14.1216 14.1213L17.4114 17.4112M6.5894 6.58916C8.14922 5.58354 10.0068 5 12.0007 5C16.4783 5 20.2686 7.94291 21.5429 12C20.836 14.2507 19.3548 16.1585 17.4114 17.4112"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          <h4 className="availBalance-amount fs-1">
            {hideBalance ? "*********" : parseInt(0).toFixed(2)}
          </h4>
          <div className="availBalance-userIdWrap d-flex align-items-center justify-content-end">
            {/* <p className="availBalance-id">
              User Id:{user !== null && user.id.slice(0, 17)}
            </p> */}

            <div className="availBalance-userIdIcons d-flex">
              <div className="availBalance-userIdIcon cursor-pointer">
                <img src={Arrow2} alt="deposit" />
                <p className="availBalance-userIdIcon-caption ">Deposit</p>
              </div>
              <div className="availBalance-userIdIcon cursor-pointer">
                <img
                  src={Arrow1}
                  alt="deposit"
                  onClick={()=>dispatch({type: 'transfer', payload: true})}
                />
                <p className="availBalance-userIdIcon-caption">Transfer</p>
              </div>
            </div>
          </div>
        </div>

        <div className="bs-moreFeatures border col-md-7 mx-auto mx-md-2">
          <div className="bs-moreFeatures-left">
            <h4 className="bs-moreFeatures-left-head fw-bolder">
              Enjoy more features using the mobile App
            </h4>
            <p className="bs-moreFeatures-left-caption ">
              Have full access to features such as Crypto Exchange, P2P trading
              and more on the mobile Application
            </p>
            <div className="d-flex flex-md-row flex-column">
              <button className="btn btn-default bg-dark text-white mt-2">
                <i className="fa-brands fa-google-play"></i> Google Play
              </button>
              <button className="btn btn-default bg-dark text-white ms-md-3 mt-2">
                <i className="fa-brands fa-apple"></i> App Store
              </button>
            </div>
          </div>
          <div className="bs-moreFeatures-right">
            <img src={Ethereum} alt="Ethereum" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableBalance;
