import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Nav from "../components/Nav";

// stylesheet
import "../assets/styles/Home.css";
import Uses from "../components/Uses";
import Steps from "../components/Steps";

// images
import Global from "../assets/global.svg";
import World from '../assets/images/world.png'
import DashboardScrnShot from "../assets/images/DashboardScrn.png";

// aos
import "aos/dist/aos.css";
import FrequentlyAsked from "../components/FrequentlyAsked";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import MockUp from "../components/MockUp";
import JoinCommunity from "../components/JoinCommunity";


const Home = () => {
  useEffect(() => {
    document.title = "CashFlakes | Home";
    setTimeout(() => 
      window.closeAlchemy(), 
      2000
    )
  }, []);

  const navigate = useNavigate();
  const alchemy = ()=>{
    window.alchemy()
  }

  return (
    <div className="w-100">
      <Nav />
      {/* Alchemy Modal */}
    <div className="modal fade" id='alchemyModal' > 
      <div className="modal-dialog modal-dialog-centered modal-lg " role="document">
        <div className="modal-content">
          <img
              id="badge-button" 
              className="badge-button"
              src="https://static.alchemyapi.io/images/marketing/alchemy-wagbi-badge-dark.png"
              alt="Alchemy Supercharged" 
              onClick={alchemy}
          />          
        </div>
      </div>
    </div>
      <div className="row w-100 my-2 py-3 mx-0 px-md-3 mt-5">
        <div className="col-md-6">
          <p className="heading1 display-4 fw-bolder pt-5 animate__animated animate__backInUp animate__slower">
            Now pay for everything
          </p>

          <p className="heading2 display-4 fw-bold fade-in-text"></p>
          <p className="cashflakes-muted animate__animated animate__fadeIn animate__slower pr-5">
            At Cashflakes, We bring web3 solutions to the local community by
            making users benefit from excellent financial services.
          </p>
          <div className="d-flex animate__animated animate__backInDown animate__slower flex-md-row flex-column">
            <button data-toggle='modal' data-target='#communityModal' className='btn rounded-lg nav-btn text-white fw-bold px-4 py-2 mx-1 my-1'>
                Get Started
            </button>
            <button
              className="btn rounded-lg fw-bold btn-waitlist px-4 py-2 mx-1 my-1"
              onClick={()=>navigate('/waitlist')}
            >
              Join our Waitlist
            </button>
          </div>
        </div>
        <div className="col-md-6 animate__animated animate__fadeIn animate__slower d-none d-md-block">
          <img
            src={DashboardScrnShot}
            alt="screenshot of the dashboard"
            className="w-100"
          />
        </div>
      </div>
      <JoinCommunity />

      <Uses />
      <Steps />

      <section className="mt-3 animation p-4 w-100" data-aos="fade-up">
        <div className="px-2">
          <h3 className="text-center fw-bold sectionHeading">
            Accept payment from anyone,
            <br /> anywhere in the world
          </h3>
          <div className="row align-items-center justify-content-between w-100">
            <div className="col-md-6 steps">
              <img src={World} alt="" className="img-fluid" />
              {/* <img src={Global} alt="" className="steps-img-rotate img-fluid w-md-75" /> */}

            </div>
            <div className="col-md-6 p-md-5 pt-5">
              <h4 className="fw-bold text-primary sectionHeading">
                Accept payment from anyone, anywhere in the world.
              </h4>
              <p className="text-secondary pt-3 section-medium">
                With Cashflakes, send and receive funds easily and quickly
                without the hindrance of conversion of cryptocurrency to any
                local currency
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Mobile features  */}
      <MockUp />

      {/* frequently asked questions  */}
      <section className="mt-5 p-md-4 animation padding10" data-aos="fade-up">
        <div className="row align-items-start justify-content-between w-100">
          <div className="col-md px-md-4 pointer ps-4 ">
            <h5 className="fw-bold">Frequently Asked Questions.</h5>
            <p className="text-secondary">
              Haven’t found what you’re looking for?
            </p>
            <p className="text-secondary">
              {" "}
              Try our <span className="text-primary fw-bold">
                Help Center
              </span>{" "}
              - We’re here to help
            </p>
          </div>

          <FrequentlyAsked />
        </div>
      </section>

      {/* Newsletter */}
      <Newsletter />
      {/* Footer  */}
      <Footer />
    </div>
  );
};

export default Home;
