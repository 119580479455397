import React from 'react';
import { Outlet } from 'react-router-dom';
import HappyClient from "../assets/happyclient.svg";
// import HappyClient from '../assets/images/HappyClient.png'

// images 
import Btc from "../assets/btc.svg";
import Eth from "../assets/eth.svg";

const Authenticate = () => {
    
    return (
        <div>
            <div className='row w-100 mx-0 box-position'>
                <div className='col-md-6 p-5'>
                    <Outlet />
                </div>


                <div className='col-6 p-5  d-none d-md-block ' >
                    {/* <img src={HappyClient} className='img-fluid' /> */}
                    <div className='auth-background h-100 '>
                         <p className='px-5 pb-5 pt-5 h2 font-weight-bold text-center text-white'><span className='text-warning'>One Wallet</span>, Make Payment from anywhere in the world 
                            <img alt='logo' src={HappyClient} className='img-fluid w-100' />
                        </p>
                   
                        <div className='shadow-lg top-left-box pe-1'>
                            <div className='d-flex px-1 justify-content-between'>
                                <img alt='miniImage' src={Btc} width='30px' height='30px' className='mt-2 bg-white shadow-lg rounded-circle mr-2' />
                                <div className='ps-3 text-right  text-end'>
                                    <p className='fw-bold my-0 pt-1 figure-caption'>Btc transfer to 0xpc..</p>
                                    <p className='fw-bold figure'>$200</p>
                                </div>
                            </div>
                        </div>    

                        <div className='shadow-lg pe-1 top-right-box'>
                            <div className='d-flex px-1 justify-content-between'>
                                    <img alt='miniImage' src={Eth} width='30px' height='30px' className='mt-2 bg-white shadow-lg rounded-circle mr-2' />
                                    <div className='ps-3 text-right  text-end'>
                                        <p className='fw-bold my-0 pt-1 figure-caption'>ETH received to 0xpc..</p>
                                        <p className='fw-bold figure-green'>$200</p>
                                    </div>
                                </div>  
                        </div>

                        <div className='shadow-lg pe-1 bottom-left-box'>
                                <div className='d-flex px-1 justify-content-between'>
                                    <img alt='miniImage' src={Eth} width='30px' height='30px' className='mt-2 bg-white shadow-lg rounded-circle mr-2' />
                                    <div className='ps-3 text-right  text-end'>
                                        <p className='fw-bold my-0 pt-1 figure-caption'>ETH received to 0xpc..</p>
                                        <p className='fw-bold figure-green'>$200</p>
                                    </div>
                                </div> 
                        </div>
                        <div className='shadow-lg pe-1 bottom-right-box'>
                        <div className='d-flex px-1 justify-content-between'>
                                    <img alt='miniImage' src={Eth} width='30px' height='30px' className='mt-2 bg-white shadow-lg rounded-circle mr-2' />
                                    <div className='ps-3 text-right  text-end'>
                                        <p className='fw-bold my-0 pt-1 figure-caption'>ETH received to 0xpc..</p>
                                        <p className='fw-bold figure-green'>$200</p>
                                    </div>
                                </div>  
                        </div>
           
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Authenticate;