import React, { useState, useEffect } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Logo from "../assets/logo1.png";
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardNavbar from '../components/DashboardNavbar';
import { useDispatch, useSelector } from 'react-redux';
import '../assets/styles/MatSideNav.css'
import "../assets/styles/Dashboard.css";

const drawerWidth = 240

const SideNav = (props)=>{
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const authUser = JSON.parse(sessionStorage.getItem('authUser'))
    const activeRoute = useSelector(state=>state.SideNavReducer.activeRoute)
    useEffect(()=>{
        console.log(authUser)
        authUser !== null
        ?
        dispatch({type: 'setUser', payload: authUser})
        :
        navigate('/auth/login')
    }, [dispatch, navigate, authUser])
    const { window } = props
    const [mobileOpen, setMobileOpen] = useState(false)
    const [toolBarText, setToolBarText] = useState('Dashboard')

    const handleDrawerToggle = ()=>{
        setMobileOpen(!mobileOpen)
    }
    const logout = ()=>{
        sessionStorage.removeItem('authUser')
        navigate('/auth/login')
    }
    const navRouter = (attr)=>{
        const { route, text } = attr
        setToolBarText(text)
        navigate(`${route}`)
    }

    const drawer = (
        <div className='bg-sidebar'>
            <Toolbar className='pt-3 pb-5'>
                <img className="sideNavLogo" src={Logo} alt="cashflakes logo" />
                <h3 className="fw-bold logo-title">
                    CashFlakes
                </h3>
            </Toolbar>
            <List>
                <ListItem disablePadding>
                    <ListItemButton className={activeRoute === 'Dashboard' ? 'active-menu' : ''} onClick={()=>navRouter({route: '/user/dashboard', text: 'Dashboard'})}>
                        <ListItemIcon>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 17.0601H12" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.21997 13.5H9.78003C11.56 13.5 12 13.94 12 15.7V19.81C12 21.57 11.56 22.01 9.78003 22.01H4.21997C2.43997 22.01 2 21.57 2 19.81V15.7C2 13.94 2.43997 13.5 4.21997 13.5Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 15C22 18.87 18.87 22 15 22L16.05 20.25" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M2 9C2 5.13 5.13 2 9 2L7.95001 3.75" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M18.5 11C20.9853 11 23 8.98528 23 6.5C23 4.01472 20.9853 2 18.5 2C16.0147 2 14 4.01472 14 6.5C14 8.98528 16.0147 11 18.5 11Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </ListItemIcon>
                        <ListItemText primary={'Dashboard'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                <ListItem disablePadding>
                    <ListItemButton className={activeRoute === 'Transaction' ? 'active-menu' : ''} onClick={()=>navRouter({route: '/user/transHistory', text: 'Transaction'})}>
                        <ListItemIcon>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 15.97C23 19.84 19.87 22.97 16 22.97L17.05 21.22" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1 7.96997C1 4.09997 4.13 0.969971 8 0.969971L6.95 2.71997" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.61963 13.0699H9.42963C10.0496 13.0699 10.5596 13.63 10.5596 14.2C10.5596 14.82 10.0596 15.33 9.42963 15.33H6.61963V13.0699Z" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.61963 15.33H9.83963C10.5496 15.33 11.1296 15.83 11.1296 16.46C11.1296 17.08 10.5496 17.59 9.83963 17.59H6.61963V15.33Z" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.41943 17.58V18.7" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M8.41943 11.95V13.0699" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M14.8498 15.33C14.8498 18.74 12.0898 21.4999 8.67977 21.4999C5.26977 21.4999 2.50977 18.74 2.50977 15.33C2.50977 11.92 5.26977 9.15991 8.67977 9.15991C8.83977 9.15991 8.98977 9.16993 9.15977 9.17993C12.1898 9.40993 14.6098 11.8299 14.8398 14.8599C14.8398 15.0099 14.8498 15.16 14.8498 15.33Z" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M21.4997 8.66998C21.4997 12.08 18.7397 14.84 15.3297 14.84H14.8397C14.6097 11.81 12.1897 9.38997 9.15967 9.15997V8.66998C9.15967 5.25998 11.9197 2.5 15.3297 2.5C18.7397 2.5 21.4997 5.25998 21.4997 8.66998Z" stroke="currentcolor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </ListItemIcon>
                        <ListItemText primary={'Transaction History'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                <ListItem disablePadding>
                    <ListItemButton className={activeRoute === 'Profile' ? 'active-menu' : ''} onClick={()=>navRouter({route: '/user/profile', text: 'Profile'})}>
                        <ListItemIcon>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.0802 8.58003V15.42C21.0802 16.54 20.4802 17.58 19.5102 18.15L13.5702 21.58C12.6002 22.14 11.4002 22.14 10.4202 21.58L4.48016 18.15C3.51016 17.59 2.91016 16.55 2.91016 15.42V8.58003C2.91016 7.46003 3.51016 6.41999 4.48016 5.84999L10.4202 2.42C11.3902 1.86 12.5902 1.86 13.5702 2.42L19.5102 5.84999C20.4802 6.41999 21.0802 7.45003 21.0802 8.58003Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M11.9999 11.0001C13.2867 11.0001 14.3299 9.95687 14.3299 8.67004C14.3299 7.38322 13.2867 6.34009 11.9999 6.34009C10.7131 6.34009 9.66992 7.38322 9.66992 8.67004C9.66992 9.95687 10.7131 11.0001 11.9999 11.0001Z" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16 16.6601C16 14.8601 14.21 13.4001 12 13.4001C9.79 13.4001 8 14.8601 8 16.6601" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </ListItemIcon>
                        <ListItemText primary={'Profile'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={logout}>
                        <ListItemIcon>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.8999 7.55999C9.2099 3.95999 11.0599 2.48999 15.1099 2.48999H15.2399C19.7099 2.48999 21.4999 4.27999 21.4999 8.74999V15.27C21.4999 19.74 19.7099 21.53 15.2399 21.53H15.1099C11.0899 21.53 9.2399 20.08 8.9099 16.54" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M15.0001 12H3.62012" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.85 8.65002L2.5 12L5.85 15.35" stroke="currentcolor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItemButton>
                </ListItem>
            </List>
        </div>
    )
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline />
            <AppBar
                className='shadow-none'
                position="fixed"
                sx={{
                width: { md: `calc(100% - ${drawerWidth}px)` },
                ml: { md: `${drawerWidth}px` },
                }}
            >
              <Toolbar className='bg-white'>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{ mr: 2, display: { md: 'none' } }}
                >
                    <MenuIcon />
                </IconButton>
                <DashboardNavbar text={toolBarText} />
              </Toolbar>
            </AppBar>
            <Box
             component="nav"
             sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                    >
                    {drawer}
                </Drawer>
            </Box>
            <Box
             component="main" 
             sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Outlet />
            </Box>
        </Box>
    )
}

export default SideNav