const inistate = {
    transfer: false
}

const DialogReducer = (state=inistate, action)=>{
    switch (action.type) {
        case 'transfer':
            return {...state, transfer: action.payload}            
    
        default:
            return state
    }
}

export default DialogReducer