import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { transferSchemas } from "../schemas";

const TransferModal = () => {
  const paymentUri = useSelector((state) => state.ApiReducer.paymentUri);
  const uri = useSelector((state) => state.ApiReducer.uri);
  const [allBanks, setAllBanks] = useState([]);
  const wallets = useSelector((state) => state.UserReducer.wallets);
  const user = useSelector((state) => state.UserReducer.userDetails);
  const [acctName, setAcctName] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    axios.get(`${paymentUri}api/banks`).then((res) => {
      console.log(res.data);
      setAllBanks(res.data.bank_codes);
    });
  }, [paymentUri]);

  const formik = useFormik({
    initialValues: {
      id: "",
      bankName: "",
      bankCode: "",
      creditAccountName: "",
      creditAccountNumber: "",
      debitAccountName: "",
      debitAccountNumber: "",
      narration: "",
      transferAmount: "",
      tokenType: "",
      privateKey: "",
    },
    validate: (values) => {
      if (values.bankName !== "" && values.creditAccountNumber.length === 10 && values.creditAccountName !== 'Please wait...' && values.narration === '') {
        setAcctName('Please wait...')
        // Find the bankcode from the bank the user selected
        let findBankCode = allBanks.find((each) => each.name === values.bankName);
        findBankCode !== undefined
          ? 
          (values.bankCode = findBankCode.code)
          : 
          (values.bankCode = "");
          // Prepare the payload to get the accountName
        let payload = {
          accountBankCode: values.bankCode,
          accountNumber: values.creditAccountNumber,
        };
        console.log(payload);
        axios.post(`${paymentUri}api/accountName`, payload).then((res) => {
          if (res.data.statusCode === 200) {
            console.log(res.data);
            setAcctName(res.data.data.AccountName)
            console.log(acctName)
            values.id = user._id;
            values.creditAccountName = acctName
            values.privateKey = user.accounts[0].account.privateKey;
            values.narration = "Test Payment";
          } else {
            // set AccountName error
          }
        });
      } else {
        // Doing nothing when all conditions are true
      }
    },
    validationSchema: transferSchemas,
    onSubmit: (values) => {
      setIsLoading(true)
      setError('')
      setAcctName(acctName)
      console.log(values, acctName);
      axios
        .post(`${uri}wallets/fiat_pay`, values, {
          headers: {
            authorization: process.env.REACT_APP_API_KEY,
            "Content-type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log(res.data);
          if(res.data.statusCode !== 200){
            setIsLoading(false)
            setError(res.data.error)
          }else{}
        });
    },
    onReset: (values, actions)=>{
      // actions.resetForm()      
      dispatch({type: 'transfer', payload: false})
    }
  });
  return (
    <div>      
      <div className="p-3">
        <div className="title d-flex justify-content-between">
          <p className="h3">Transfer</p>
          <button className="btn-close" data-dismiss="modal" onClick={formik.handleReset}>
            &times;
          </button>
        </div>
        <div className="alert alert-warning px-2 py-2">
          <span className="small">
            Note: Users can make direct transfer from their wallet to their
            local banks
          </span>
        </div>
        <div className="content">
              {   error !== ''
                  ?
                  <div className='alert alert-danger'>
                      <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error}</span>
                  </div>
                  :
                  ''
              }
          <form>
            <div className="form-group">
              <label className="py-2">Wallet to charge from</label>
              <select
                className="form-select rounded-0 w-100"
                name="tokenType"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">
                  Please select wallet to proceed transaction
                </option>
                {wallets.map((each, i) => (
                  <option value={i} key={i}>
                    {Object.keys(each)[0]}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="py-2">Account Number</label>
              <input
                className="form-control rounded-0"
                name="creditAccountNumber"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your 10-digit NUBAN"
              />
            </div>
            <div className="form-group">
              <label className="py-2">Bank Name</label>
              <select
                className="form-select rounded-0"
                name="bankName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <option value="">Select Bank Name</option>
                {allBanks.map((each, i) => (
                  <option key={i} value={each.name}>
                    {each.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="py-2">Account Name</label>
              <input
                className="form-control rounded-0"
                name="creditAccountName"
                value={acctName}
                placeholder="Name of Account Holder"
                disabled
              />
            </div>
            <div className="form-group">
              <label className="py-2">Amount</label>
              <input
                className="form-control rounded-0"
                name="transferAmount"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Amount in Naira"
              />
            </div>
            <div className="my-1 d-flex justify-content-end">
              <button
                type="submit"
                onClick={formik.handleSubmit}
                disabled={!formik.isValid}
                className={isLoading ? 'btn disabled py-2 text-primary' : 'btn btn-primary text-white py-2' }
              >
                {
                  isLoading
                  ?
                  'Please wait...'
                  :
                  'Continue'
                }
              </button>
            </div>
          </form>
        </div>
      </div>        
    </div>
  );
};

export default TransferModal;
