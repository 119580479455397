import React, { useEffect } from "react";
import "../assets/styles/Steps.css";

// images
import Dummy from "../assets/dummy.png";
import Step1Image from "../assets/images/Register- Cashflakes 2 (1).png";
import Step2Image from "../assets/images/BVN verification 1.png";
import Step3Image from "../assets/images/BVN verification 2.png";

// aos
import AOS from "aos";
import "aos/dist/aos.css";


const Steps = () => {
  useEffect(()=>{
    AOS.init({duration : 3000})
  }, [])
  return (
    <div className="animation p-4 w-100" >
      <div className="container-fluid">
        <h3 className="text-left fw-bold pb-3 sectionHeading">
          Getting Started is easy.
        </h3>

        {/* cards  */}
        <div className="step1 p-md-4  d-md-flex align-items-center justify-content-between" data-aos="fade-up">
          <div>
            <h5 className="fw-bold step-list">
              1. Create an account on CashFlakes
            </h5>
            <p className="steps_description section-small">
              Start out with cashflakes by adding your email Address password
            </p>
          </div>

          <div className="steps">
            <img
              className="steps-img-front img-fluid w-sm-50 "
              src={Step1Image}
              alt="step1image"
            />
            <img
              className="steps-img-back img-fluid w-sm-50 "
              src={Step1Image}
              alt="step1image"
            />
          </div>
        </div>

        <div className="step2 p-md-4 mt-5 d-md-flex align-items-center justify-content-between" data-aos="fade-up">
          <div>
            <h5 className="fw-bold step-list">2. Bank Verification </h5>
            <p className="steps_description section-small">
              When you provide your BVN, it helps cashflakes protect your
              financial interest and combat AML with proper KYC
            </p>
          </div>

          <div className="steps">
            <img
              className="steps-img-front img-fluid w-sm-50 "
              src={Step2Image}
              alt="step2image"
            />
            <img
              className="steps-img-back img-fluid w-sm-50 "
              src={Step2Image}
              alt="step2image"
            />
          </div>
        </div>

        <div className="step3 mt-5 p-md-4 d-md-flex align-items-center justify-content-between" data-aos="fade-up">
          <div>
            <h5 className="fw-bold step-list">3. Take a Selfie</h5>
            <p className="steps_description section-small">
              Thiis is to ensure safety for a every of our Users.
            </p>
          </div>

       
        <div className="steps">
            <img
              className="steps-img-front img-fluid w-sm-50 "
              src={Step3Image}
              alt="step3image"
            />
            <img
              className="steps-img-back img-fluid w-sm-50 "
              src={Step3Image}
              alt="step3image"
            />
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Steps;
