import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import DangerIcon from "../assets/images/danger.svg";
import { useSelector } from "react-redux";
import Send from "./Send";
import Receive from "./Receive";
import Swap from "./Swap";

const WalletModal = () => {
  const [key, setKey] = useState('send')
  const chainWallet = useSelector(state=>state.WalletReducer.chainWallet)

  return (
    <div
      className="modal fade "
      id="btcModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    > 
      <div className="modal-dialog modal-dialog-centered modal-lg " role="document">
        <div className="modal-content modal-wrap">
          <div className="modal-header modal-header-custom">
            <h5 className="modal-title fw-bold" id="exampleModalLongTitle">
              {Object.keys(chainWallet)[0] +  ' - ' + '(0.04' + Object.keys(chainWallet)[0] + ' - ' + 'N' + Object.values(chainWallet)[0] + ')'}
            </h5>
            <button
              type="button"
              className="close close-btn-custom"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-dashboard-error-message">
            <span className="pe-2">
              <img src={DangerIcon} alt="Information Icon" />
            </span>
            Note: Please kindly ensure the wallet you are  transacting with is a <strong>{Object.keys(chainWallet)[0]}</strong> wallet
          </div>
          <div className="modal-body">
            <Tabs id="token-tab" variant="pills" activeKey={key} onSelect={(k)=>setKey(k)}>
              <Tab eventKey='send' title="Send">
                <Send />
              </Tab>
              <Tab eventKey='receive' title='Receive'>
                <Receive />
              </Tab>
              <Tab eventKey='swap' title='Swap'>
                <Swap />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModal;
