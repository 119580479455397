import React, { useState } from 'react';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import CreatePin from './CreatePin';
import UpdatePassword from './UpdatePassword';

const Settings = ()=>{
    const [key, setKey] = useState('password')
    return (
        <div className='mx-2 mx-md-0'>
            <p className='fs-bold h3'>Manage Security</p>
            <p className='text-muted'>Update password and transaction PIN here</p>
            <Tabs id='settings-tab' activeKey={key} onSelect={(k)=>setKey(k)} >
                <Tab eventKey='password' title='Password'>
                    <UpdatePassword />
                </Tab>
                <Tab eventKey='pin' title='Transaction PIN'>
                    <div className='my-5'>
                        <p className='text-center fw-bold h3'>
                            Transaction PIN
                        </p>
                        <p className='text-center text-muted'>
                            This is a secret PIN used to complete all transaction. Don't have? Create one <span onClick={()=>setKey('create_pin')} className='text-primary cursor-pointer fw-bold'>here</span>
                        </p>
                    </div>
                </Tab>
                <Tab eventKey='create_pin' >
                    <CreatePin />
                </Tab>
            </Tabs>
        </div>
    )
}

export default Settings