const initState = {
    chainWallet: {}
}

const WalletReducer = (state=initState, action)=>{
    switch (action.type) {
        case 'setChain':
            return {...state, chainWallet: action.payload}
    
        default:
            return state;
    }
}

export default WalletReducer