import React from 'react';

const CreatePin = ()=>{
    return (
        <>
            <p className='fw-bold h5 my-3'>Create PIN</p>
            <div className='row w-100 mx-0'>
                <div className='col-md-6'>
                    <form>
                        <div className='form-group my-1'>
                            <label className='py-1'>Enter Transaction PIN</label>
                            <input placeholder='Enter 4-digits code for your PIN' className='form-control form-control-lg rounded-0' />
                        </div>
                        <div className='form-group my-1'>
                            <label className='py-1'>Confirm Transaction PIN</label>
                            <input placeholder='Enter 4-digits code for your PIN' className='form-control form-control-lg rounded-0' />
                        </div>
                        <div className='d-flex justify-content-end my-3'>
                            <button className='btn btn-primary rounded-0'>Create PIN</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreatePin