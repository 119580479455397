import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Authenticate from "./components/Authenticate";
import NotFound from "./pages/404";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Otp from "./pages/Otp";
import BvnVerification from "./pages/BvnVerification";
import SelfieVerification from "./pages/SelfieVerification";
import ForgotPassword from "./pages/ForgotPassword";
import Dashboard from "./pages/Dashboard";
import TransactionHistory from "./pages/TransactionHistory";
import Profile from "./pages/Profile";
import WaitList from "./pages/WaitList";
import SideNav from "./pages/SideNav";
import About from "./pages/About";
import ResetPassword from "./pages/ResetPassword";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/" 
          element={<Home />} 
        />
        <Route 
          path="/about"
          element={<About />}
        />        
        <Route path="/user" element={<SideNav/>}>
          <Route 
            path="/user/dashboard" 
            element={<Dashboard />} 
          />
          <Route 
            path="/user/transHistory" 
            element={<TransactionHistory />} 
          />
          <Route 
            path="/user/profile" 
            element={<Profile />} 
          />
        </Route>
        <Route 
          path="/waitlist"
          element={<WaitList />}
        />
        <Route path="/auth" element={<Authenticate />}>
          <Route 
            path="/auth/login" 
            element={<Login />} 
          />
          <Route 
            path="/auth/register" 
            element={<Register />} 
          />
        </Route>
        <Route 
          path="/forgotPword" 
          element={<ForgotPassword />} 
        />
        <Route 
          path="/confirmation/:otp"
          element={<ResetPassword />}
        />
        <Route 
            path="/verification/bvn" 
            element={<BvnVerification />} 
          />
          <Route
            path="/verification/selfie"
            element={<SelfieVerification />}
          />
        <Route 
          path="/verification/otp" 
          element={<Otp />} 
        />
        <Route 
          path="/*" 
          element={<NotFound />} 
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
