import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "../assets/styles/TransactionHistory.css";

import InformationIcon from "../assets/images/information.svg";
import Zenith from "../assets/images/Zenith.svg";
import Eth from "../assets/images/Eth.svg";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ProfileAvatar from "../assets/images/56.svg";
import ArrowDown from "../assets/images/arrow-down.svg";
import { BiArrowBack } from "react-icons/bi";
import axios from 'axios';

const TransactionHistory = ()=>{

    const sidenav = useSelector(state=>state.SideNavReducer.sidenav)
    const uri = useSelector(state=>state.ApiReducer.uri)
    const dispatch = useDispatch();
    const user = useSelector(state=>state.UserReducer.userDetails)
    const [history, setHistory] = useState([])
    const [error, setError] = useState('')
    const [key, setKey] = useState('payment')
    
    useEffect(()=>{
        document.title = 'CashFlakes | Transaction History'
        dispatch({type: 'setActiveRoute', payload: 'Transaction'})
        axios.post(`${uri}wallets/history`, 
        {id: user._id},
        {headers: {
            "authorization": process.env.REACT_APP_API_KEY,
            "Content-type": "application/json",
            "Accept": "application/json"
        }}
        ).then(res=>{
            console.log(res.data)
            if (res.data.statusCode === 200) {
                setHistory(res.data.userHistory)
            } else {
                setError('Internal Server Error')
            }
        }).catch(()=>{
            setError('Network Error')
        })
    }, [uri, user._id, dispatch])

    return (
        <div className="mt-5 pt-1">
            <div className="r-transactions">
                <div className="">
                    <Tabs id='profile-tab' variant='pills' className='my-4' activeKey={key} onSelect={(k)=>setKey(k)} >
                        <Tab eventKey="payment" title="Payment Transfer">
                            {/* warning / Error message  */}
                            <div className="dashboard-error-message" >
                                <span  className='pe-2'><img src={InformationIcon} alt="Information Icon" /></span>
                                To be able to complete a transaction, kindly create a transaction PIN <strong>here</strong>
                            </div>  
                            {/* Payment History */}
                            <div className="r-transaction d-flex flex-wrap justify-content-center justify-content-between align-items-center">
                                <div className="bank-details d-inline-flex p-2">
                                    <img src={Zenith} alt="bank icon" />

                                    <div className="bd-transaction-details">
                                        <h5 className="bd-sender-account-name">
                                            Adedayo Omoboboye
                                        </h5>
                                        <h5 className="bd-receiver-account-name">
                                            Transfer - Ayo Seun Solomon
                                        </h5>
                                    </div>
                                </div>
                                <div className="transaction-status">
                                    <div className="pointer ts-value-pend" >
                                        Pending
                                    </div>
                                </div>
                                <div className="transaction-bal">
                                    <h4 className='tb-amt '>N230,000.00</h4>
                                    <p className='tb-date'>06 Dec 2022, 12:09 PM</p>
                                </div>
                            </div>                   
                        </Tab>
                        <Tab eventKey="wallet" title="Wallet Transfer">
                            {/* warning / Error message  */}
                            <div className="dashboard-error-message" >
                                <span  className='pe-2'><img src={InformationIcon} alt="Information Icon" /></span>
                                To be able to complete a transaction, kindly create a transaction PIN <strong>here</strong>
                            </div> 
                             {/*Wallet History  */}
                             <div className="r-transaction d-flex flex-wrap justify-content-center justify-content-between align-items-center">
                                <div className="bank-details d-inline-flex p-2">
                                    <img src={Eth} alt="Ethereum icon"  />

                                    <div className="bd-transaction-details">
                                        <h5 className="bd-sender-account-name">
                                            0Xpytrewxz23898uc
                                        </h5>
                                        <h5 className="bd-receiver-account-name">
                                        ETH- Recieved
                                        </h5>
                                    </div>
                                </div>
                                <div className="transaction-status">
                                    <div className="pointer ts-value-success" >
                                        Success
                                    </div>
                                </div>
                                <div className="transaction-bal">
                                    <h4 className='tb-amt '>0.300ETH</h4>
                                    <p className='tb-date'>06 Dec 2022, 12:09 PM</p>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>                                                                   
            </div>
        </div>
    )
}

export default TransactionHistory