import { useFormik } from 'formik';
import React from 'react'
import { newsletterSchemas } from '../schemas';

const Newsletter = () => {
    
    const onSubmit = async (values, actions ) => {

        await new Promise((resolve) => setTimeout(resolve, 1000));
        actions.resetForm();
    }

    const { values, handleBlur, handleChange, errors, touched, handleSubmit, isSubmitting  } = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: newsletterSchemas, 
        onSubmit 
    });


  return (
       <div className='container-fluid'>
             <div className="row mt-2 mx-md-3 justify-content-center subscribe">
            <div className="col-md-5 p-md-5 p-4">
                <h4 className='sub-title text-center fw-bold'>Subscribe to our Newsletter</h4>
                <p className='section-medium-small text-center'>Have full access to features such as Crypto Exchange, P2P trading and more on the mobile Application </p>
                <form>
                    <div className="input-group mb-3">
                        <input 
                            type="text" 
                            className="form-control subscribe-email" 
                            placeholder='E-mail Address'
                            aria-label="Recipient's username" 
                            aria-describedby="basic-addon2" 
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name='email'
                            value={values.email}
                            />
                        <div className="input-group-append mx-1" onClick={handleSubmit}>
                            <span className="input-group-text arrow p-3 cursor-pointer" id="basic-addon2"> <i className="fa fa-long-arrow-right " aria-hidden="true"></i> </span>
                        </div>
                    </div>
                    {errors.email && touched.email ? <div className='text-info text-center font-weight-bolder h6'>{errors.email}</div> : ''}
                </form>
            </div>
        </div>
       </div>
  )
}

export default Newsletter