const initState = {
    sidenav: false,
    slider: false,
    activeRoute: ''
}

const SideNavReducer = (state=initState, action)=>{
    switch (action.type) {
        case 'setSideNav':
            return {...state, sidenav: action.payload}
        case 'setSlider':
            return {...state, slider: action.payload}
        case 'setActiveRoute':
            return {...state, activeRoute: action.payload}
    
        default:
            return state
    }
}

export default SideNavReducer