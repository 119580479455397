const initState = {
    userDetails: {accounts: []},
    wallets: []
}

const UserReducer = (state=initState, action)=>{
    switch (action.type) {
        case 'setUser':
            return {...state, userDetails: action.payload}
        case 'setWallet':
            return {...state, wallets: action.payload}
    
        default:
            return state
    }
}

export default UserReducer