import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InformationIcon from "../assets/images/information.svg";
import Zenith from "../assets/images/Zenith.svg";
import Eth from "../assets/images/Eth.svg";
import Search from "../assets/images/search-normal.svg";
import TransferModal from "../components/TransferModal";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "../components/DashboardNavbar";
import AvailableBalance from "../components/AvailableBalance";
import MultichainSlider from "../components/MultichainSlider";
import WalletModal from "../components/WalletModal";
import { Dialog } from "@mui/material";

const Dashboard = () => {
  const tokenUri = useSelector((state) => state.ApiReducer.tokenUri);
  const sidenav = useSelector((state) => state.SideNavReducer.sidenav);
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const navigate = useNavigate();
  const open = useSelector(state=>state.DialogReducer.transfer)
  // const tokenSocket = socketClient(`${tokenUri}wallets/tokenbalance/${user._id}`)

  useEffect(() => { 
    document.title = "CashFlakes | Dashboard";
    dispatch({type: 'setActiveRoute', payload: 'Dashboard'})
    console.log(user);
    if (user !== null) {
      const payload = {
        address: user.accounts[0].address,
        id: user._id,
      };
      const socket = new WebSocket(
        `${tokenUri}wallets/tokenbalance/${user.id}`
      );
      socket.onopen = (e) => {
        socket.send(JSON.stringify(payload));
      };
      socket.onmessage = (e) => {
        if (e.data === "connected" || "Client connection already added") {
          const res = JSON.parse(e.data);
          if (typeof res === "object") {
            console.log(res);
            dispatch({ type: "setWallet", payload: res });
          } else console.log("Not what I want");
        } else console.log("Error");
      };
    } else {
      // navigate("/auth/login");
    }
  }, [dispatch, navigate, user, tokenUri]);

  return (
    <div className="mt-5 pt-3">
      {
        parseInt(user.pin) == 0
        ?
        <div className="dashboard-error-message">
          <span className="pe-2">
            <img src={InformationIcon} alt="Information Icon" />
          </span>To be able to complete a transaction, kindly create a transaction PIN{" "}<strong className="cursor-pointer" onClick={()=>navigate('/user/profile')}>here</strong>
        </div>
        :
        ''
      }
      <AvailableBalance />
        <MultichainSlider />
      <div className="sample"></div>

      <Dialog open={open}>
        <TransferModal />        
      </Dialog>

      <WalletModal />
    </div>
  );
};

export default Dashboard;
