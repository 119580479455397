import React, { useEffect, useState } from 'react';
import CashFlakesLogo from "../assets/logo1.png";
import ProfileFemale from "../assets/profilePic1.svg";
import Camera from "../assets/Camera.svg";
import MyWebCam from '../components/Webcam';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Alert, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const SelfieVerification = () => {
    const navigate = useNavigate()
    const api = useSelector(state=>state.ApiReducer.uri)
    const [image, setImage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const userDetails = JSON.parse(sessionStorage.getItem('bvnAuth'))
    const [dialogOpen, setDialogOpen] = useState(false)
    const [error, setError] = useState('')
    const changeCamera = (screenShot)=>{        
        setImage(screenShot)
    }
    useEffect(()=>{
        document.title = 'CashFlakes | Selfie Verification'
    })

    const uploadImage = ()=>{
        const userDetails = JSON.parse(sessionStorage.getItem('bvnAuth'))
        setError('')
        setIsLoading(true)
        const payload = { image, id: userDetails.id }
        console.log(payload)
        axios.post(
            `${api}auth/selfie`,
            payload, 
            {
                headers:{
                    'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        ).then(res=>{
            setIsLoading(false)
            console.log(res.data)
            if (res.data.data.statusCode == 200) {
                sessionStorage.removeItem('bvnAuth')
                setDialogOpen(true)
                setTimeout(()=>{
                    navigate('/auth/login')
                }, 4000)
            } else {
                setError(res.data.data.error)
            }
        }).catch(err=>{
            setIsLoading(false)
            console.log(err)
            setError('Internal Server Error')
        })        
    }
  return (
    <>
        <div className='text-center mb-4 mt-5'>
            <pre href='/' 
                className='text-dark links font-weight-bold text-lowercase h3 animate__animated animate__slideInLeft animate__slow text-center pt-3 px-md-5 px-1 fs-9 pt-3' 
            >
                <img alt='logo' src={CashFlakesLogo} width='45px' className='my-0' /> <span className='text-capitalize mt-5 h6 fw-bolder'>CashFlakes</span>
            </pre>
        </div>

        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-6'>
                    <h3 className='text-center form-titles fw-bolder'>Take a Selfie</h3>
                    {
                        error !== ''
                        &&
                        <Alert severity='error' className='mb-3'>
                            {error}
                        </Alert>
                    }
                    <p className='text-center text-secondary'>Always ensure that your eyes, ears, nose are visible to the camera for verification. </p>
                    <div className="d-flex justify-content-center pointer" >
                        <img data-toggle='modal' data-target={isLoading ? '' : '#webcam'} src={image !== '' ? image : Camera} className="rounded-circle img-fluid w-25  " alt="..." />
                    </div>                    
                    <button onClick={uploadImage} type='submit' className={isLoading ? 'btn w-100 disabled py-2 my-4 text-primary py-2 fw-bold' : 'btn theme-bg-btn text-white fw-bold w-100 rounded-0 mt-5 py-2' }>
                        {
                            isLoading
                            ?
                            'Please wait...'
                            :
                            'Verify'
                        }
                    </button>
                </div>
            </div>
        </div>
        <MyWebCam image={changeCamera} />
        <Dialog open={dialogOpen} maxWidth={'xs'} fullWidth={true} >
            <DialogContent>
                <div className='d-flex justify-content-center'>
                    <img src={image} className='img-fluid rounded-circle mb-4' width={'150px'} height={'150px'} />
                </div>
                <p className='text-center text-primary my-0'>
                    Registration Successful
                </p>
                <p className='text-center'>
                    Hurray! Registration completed, you would be redirected to the Sign In page shortly.
                </p>
            </DialogContent>
        </Dialog>
    </>
  )
}

export default SelfieVerification