import React from 'react';
import { useNavigate } from 'react-router-dom';
// images 
import CashFlakesLogo from '../assets/logo1.png';

const Nav = () => {
    
    const navigate = useNavigate();

    return (    
        <nav className='navbar fixed-top navbar-expand-md bg-white text-dark px-4 mb-5 w-100'>
            <a href='/' className='navbar-brand text-dark font-weight-bold text-lowercase h3 animate__animated animate__slideInDown animate__slow  px-1 fs-9 pt-3' >
                <img alt='logo' src={CashFlakesLogo} width='35px' /> <strong className='text-capitalize mt-5 fw-bolder'>CashFlakes</strong>
            </a>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navmenu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div id='navmenu' className='collapse navbar-collapse justify-content-center animate__animated animate__fadeInDown animate__slow py-0'>
                <ul className='navbar-nav ms-auto'>
                    <li className='nav-item my-0 mx-2'>
                        <a href='/' className='nav-link text-dark fs-9'>Home</a>
                    </li>
                    <li className='nav-item my-0 mx-2'>
                        <a href='/' className='nav-link text-dark fs-9'>Services</a>
                    </li>
                    <li className='nav-item my-0 mx-2'>
                        <a href='/about' className='nav-link text-dark fs-9'>About Us</a>
                    </li>
                </ul>
            </div>

            <div id='navmenu' className='collapse navbar-collapse justify-content-end animate__animated animate__fadeInDown animate__slow py-0'>
                <ul className='navbar-nav'>
                    {/* <li className='nav-item my-md-0 my-1 mx-2'>
                        <button className='btn btn-outline-primary font-weight-bold mx-1 px-5 rounded-0' data-toggle='modal' data-target='#alchemyModal'>
                            Sign In
                        </button>
                    </li> */}
                    <li className='nav-item my-md-0 my-1 mx-2'>
                        <button className='btn nav-btn-outline btn-outline-primary text-primary font-weight-bold mx-1 px-5'>Coming Soon..</button>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Nav;
