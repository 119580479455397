import Aos from 'aos'
import React, { useEffect } from 'react'
import "aos/dist/aos.css";

const FrequentlyAsked = () => {
    useEffect(()=>{
        Aos.init({duration: 2000})
    })
  return (
    
    <div className='col-md' data-aos='fade-up'>
    <div className="accordion accordion-flush" id="accordionFlushExample">
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingOne">
        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
        What is CashFlakes 
        </button>
        </h2>
        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                Cashflakes is a blockchain assisted payment platform that uses blockchain technology to securely and transparently process transactions. This can provide a more efficient and secure alternative to make payments globally directly from your crypto wallets.
            </div>
        </div>
    </div>
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingTwo">
        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
            How does it work? 
        </button>
        </h2>
        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                When a user initiates a payment, their crypto wallet is charged depending on the token or coin of their choice. The transaction is then verified and processed by multiple nodes in the network, ensuring its security and accuracy. The funds are then transferred directly between the beneficiary's bank account without the need of the reciever to have a crypto wallet or be a web3 user.
            </div>
        </div>
    </div>
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingThree">
        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            Is it secure? 
        </button>
        </h2>
        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                Yes, blockchain technology provides a high level of security for transactions processed through the platform. The decentralized nature of the blockchain network means that there is no single point of failure, and transactions are verified by multiple nodes in the network, making it difficult for malicious actors to tamper with the system.
            </div>
        </div>
    </div>
    
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingFour">
        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
            Can I use it to pay for everyday purchases? 
        </button>
        </h2>
        <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                Yes, whether through the Cashflakes mobile app or through the web platform you can perform varieties of transactions and make everyday purchases. The platform is designed to be user-friendly and accessible, making it easy for you to get started in just 3 steps.
            </div>
        </div>
    </div>
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingFive">
        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
            What types of currency can I use on the platform? 
        </button>
        </h2>
        <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                Cashflakes provides you with endless varieties of stable coins and trusted tokens. Our support cryptocurrencies come provided once with your registered account, Check the cashflakes's documentation to see what types of currency are supported.
            </div>
        </div>
    </div>
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingSix">
        <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
            How do I get started with the blockchain assisted payment platform?
        </button>
        </h2>
        <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                To get started with the blockchain assisted payment platform, you will typically need to create an account and download wallet. Once you have a wallet, you can add funds to it and start using the platform for your payments. The specific steps for getting started will depend on the platform you choose, so be sure to consult the platform's documentation for more information.
            </div>
        </div>
    </div>


    </div>
</div>
  )
}

export default FrequentlyAsked
