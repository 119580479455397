import React, { useEffect, useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import MockUp from "../components/MockUp";
import "../assets/styles/WaitList.css";
import UserShield from "../assets/images/UserShield.svg";
import Animation from "../assets/images/animation.png";
import AirDrop from "../assets/images/151.svg"
import Component1 from "../assets/images/Component 1.png"
import { useFormik } from "formik";
import { waitlistSchema } from "../schemas";
import { useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const WaitList = () => {
  const navigate = useNavigate()
  const uri = useSelector(state=>state.ApiReducer.uri)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  useEffect(() => {
    document.title = "CashFlakes | Wait List";
  }, []);
  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      wallet: ''
    },
    validationSchema: waitlistSchema,
    onSubmit: (values)=>{
      console.log(values)
      setIsLoading(true)
      axios.post(`${uri}waitlist`, values, {
        headers: {
          'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
          'Content-type': 'application/json',
          'Accept': 'application/json'
        }
      }).then(res=>{
        setIsLoading(false)
        console.log(res.data)
        if (res.data.data.statusCode == 200) {          
          alert('Congratulations, You have successfully joined our waitlist')
          navigate('/')
        }else alert(res.data.data.data)
      }).catch(err=>{
        setIsLoading(false)
        !err.response ? setError(err.name) : setError('Internal Server Error')
      })
    }
  })
  return (
    <>
      <Nav />
      {/* ai */}
      <div className="waitlist mt-5 pt-5 d-flex justify-content-center w-100 mx-0">
        <div className="col-lg-5 col-md-6 col-sm-12 px-0 mx-0">
          <div className="user-shield d-flex justify-content-center">
            <img src={UserShield} alt="User Shield" />
          </div>
          <p className="fw-bolder text-center text-secondary h1 py-3 Waitlist-prompt">
            Join the Waitlist to enjoy
          </p>
          <div className="px-5">
            <p className="text-primary fw-bolder h3"><span className="text">Borderless payment</span></p>
            {/* <img src={Component1} alt="component1" height='100px' /> */}
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group py-2">
                <label className="py-1">Email Address</label>
                <input
                  placeholder="Enter your Email Address"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control form-control-lg rounded-0 py-3 input-field"
                />
                {formik.errors.email && formik.touched.email ? <div className='text-danger'>{formik.errors.email}</div> : " "}
              </div>
              <div className="form-group py-2">
                <label className="py-1">First Name</label>
                <input
                  placeholder="Enter your First Name"
                  name="firstName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control form-control-lg rounded-0 py-3 input-field"
                />
                {formik.errors.firstName && formik.touched.firstName ? <div className='text-danger'>{formik.errors.firstName}</div> : " "}
              </div>
              <div className="form-group py-2">
                <label className="py-1">Last Name</label>
                <input
                  placeholder="Enter your Last Name"
                  name="lastName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control form-control-lg rounded-0 py-3 input-field"
                />
                {formik.errors.lastName && formik.touched.lastName ? <div className='text-danger'>{formik.errors.lastName}</div> : " "}
              </div>
              <div className="form-group py-2">
                <label className="py-1">Wallet Address</label>
                <input
                  placeholder="to Airdrop CFT token"
                  name="wallet"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="form-control form-control-lg rounded-0 py-3 input-field"
                />
                {formik.errors.wallet && formik.touched.wallet ? <div className='text-danger'>{formik.errors.wallet}</div> : " "}
              </div>
              <div className="form-group pt-2">
                <button type="submit" className={!isLoading ? 'btn btn-primary col-12 py-2' : 'btn disabled py-2 my-4 text-primary'}>
                  {
                    isLoading
                    ?
                    <>
                      Please wait...
                    </>
                    :
                    <>
                      Join Waitlist <i className="fa fa-arrow-right"></i>
                    </>
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* Airdrop Box */}
      <div className="border mx-2 my-5 p-4 d-flex justify-content-between align-items-center">
        <div>
          <p className="fw-bold h3">
            <span className="text-purple">Airdrop</span>{" "}
            <span className="text-primary">coming soon</span>
          </p>
          <p className="text-muted">Watch out for CFT pre-sales</p>

          <img className="w-75" src={Animation} alt="animation" />
        </div>

        <div>
          <img className="w-75" src={AirDrop} alt="airdrop image" />
        </div>
      </div>
      <MockUp />
      <Footer />
    </>
  );
};

export default WaitList;
