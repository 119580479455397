import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { tokenTransferSchemas } from '../schemas';

const Send = ()=>{
    const user = useSelector((state) => state.UserReducer.userDetails);
    const chainWallet = useSelector(state=>state.WalletReducer.chainWallet)
    const uri = useSelector((state) => state.ApiReducer.uri);
    const [error, setError] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const formik = useFormik({
      initialValues: {
        privateKey: '',
        tokenType: '',
        address: '',
        amount: ''
      },
      validationSchema: tokenTransferSchemas,
      onSubmit: (values)=>{
        setIsLoading(true)
        setError('')
        values.privateKey = user.accounts[0].account.privateKey;
        values.address = user.accounts[0].account.privateKey; 
        console.log(values)
        axios
          .post(`${uri}wallets/tokens/transfer`, values, {
            headers: {
              authorization: process.env.REACT_APP_API_KEY,
              "Content-type": "application/json",
              Accept: "application/json",
            }
          })
            .then((res)=>{
              console.log(res.data);
              if(res.data.statusCode !== 200){
                setIsLoading(false)
                setError(res.data.error)
              }else{}
            })       
      }
    })

    return (
        <div>
            <div className="content">
                <form>
                    {   error !== ''
                       ?
                        <div className='alert alert-danger my-2 col-md-9'>
                            <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error}</span>
                        </div>
                        :
                        ''
                    }
                  <div className="form-group my-2 col-md-9">
                    <label className="py-1 multichain-modal-label">
                      Wallet to charge from
                    </label>
                    <select
                      className="form-select rounded-0 p-3"
                      name="tokenType"
                      onChange={formik.handleChange}
                    >
                      <option value=''>Select wallet to charge from</option>
                      <option value={1}>{Object.keys(chainWallet)[0] +  ' - ' + '(0.04' + Object.keys(chainWallet)[0] + ' - ' + 'N' + Object.values(chainWallet)[0] + ')'}</option>
                    </select>
                  </div>
                  <div className="form-group my-2 col-md-9">
                    <label className="py-1 multichain-modal-label">
                      Wallet Address
                    </label>
                    <input
                      className="form-control rounded-0 p-3"
                      name="address"
                      placeholder="Enter Your Wallet Address"
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="form-group my-2 col-md-9">
                    <label className="py-2 multichain-modal-label">Amount</label>
                    <input
                      className="form-control rounded-0 p-3"
                      name="amount"
                      placeholder="Enter Amount"
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="mt-5 d-flex justify-content-end">
                    <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        disabled={!formik.isValid}
                        className={isLoading ? 'btn disabled py-2 text-primary' : 'btn btn-primary text-white py-2 px-5' }
                      >
                        {
                          isLoading
                          ?
                          'Please wait...'
                          :
                          'Continue'
                        }
                      </button>
                  </div>
                </form>
              </div>
        </div>
    )
}

export default Send