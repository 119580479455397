import React, { useState } from 'react'
import CashFlakesLogo from "../assets/logo1.png";
import { useFormik } from 'formik';
import { bvnSchemas } from '../schemas'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert } from '@mui/material';
import { useSelector } from 'react-redux';


const BvnVerification = () => {
    const api = useSelector(state=>state.ApiReducer.uri)
    const userDetails = JSON.parse(sessionStorage.getItem('bvnAuth'))
    const [openDialog, setOpenDialog] = useState(true)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')


    const {  values, handleBlur, handleChange, errors, touched, handleSubmit, isSubmitting } = useFormik({
        initialValues: {    
           bvn : ''
        },
        validationSchema : bvnSchemas,
        onSubmit: (values)=>{
            setIsLoading(true)
            values.id = userDetails.id
            console.log(values)
            axios.post(
                `${api}auth/bvn_validate`, 
                values,
                {
                    headers: {
                        'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                        'Content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            ).then((res)=>{
                setError('')
                setIsLoading(false)
                console.log(res.data)
                if (res.data.data.statusCode == 200) {                    
                    navigate('/verification/selfie')
                } else {
                    setError(res.data.data.error.message)
                }
            }, (err)=>{
                setIsLoading(false)
                setError('Internal Server Error')
                console.log(err)
            })
        } 
    })

  return (
    <div>
    <div className='text-center my-5'>
        <pre className='text-dark links font-weight-bold text-lowercase h3 animate__animated animate__slideInLeft animate__slow text-center pt-3 px-md-5 px-1 fs-9 pt-3' >
            <img alt='logo' src={CashFlakesLogo} width='45px' className='my-0' /> <span className='text-capitalize mt-5 h5 fw-bolder'>CashFlakes</span>
        </pre>
    </div>

        
<div className='container'>
        <div className="row justify-content-center">
            <div className="col-md-6">
                 <h3 className='text-center form-titles font-weight-bolder fw-bolder'>BVN Verification</h3>
                <p className='text-center text-secondary'> When you provide your BVN, it helps cashflakes protect your financial interest and combat AML with proper KYC     </p>
            
                <form className='px-0 px-md-5 ' onSubmit={handleSubmit}>
                    {
                        error !== ''
                        &&
                        <Alert severity='error' className='mb-2'>
                            {error}
                        </Alert>
                    }
                    <div className='form-group'>
                        <label className='text-left'>BVN Number</label>
                        <input className='form-control bvn-text rounded-0'
                        placeholder='Enter BVN number'
                        value={values.bvn}
                        onBlur={handleBlur} 
                        autoComplete='off'
                        onChange={handleChange}
                        name='bvn'
                        /> 
                        { touched.bvn && <div className='text-danger'>{ errors.bvn}</div>}  
                    </div>
                    <button disabled={isLoading} type='submit' className={isLoading ? 'btn rounded-0 text-primary font-weight-bolder w-100 disabled my-2 py-2' : 'btn theme-bg-btn text-white rounded-0 fw-bold w-100 my-2 py-2'} >
                        {
                            isLoading
                            ?
                            'Please wait...'
                            :
                            'Submit'
                        }
                    </button>
                </form>
                
            </div>
        </div>
            
    </div>
    {/* Dialog */}
    <Dialog open={openDialog}>
        <DialogTitle className='form-titles fw-light'>BVN Verification</DialogTitle>
        <DialogContent>
            <p className='text-danger fs-7'>
                **Note: This stage of the registration is optional!
            </p>
            Will you like to verify your BVN now?
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>setOpenDialog(false)} className='theme-bg-btn rounded-0'>Yes, I will</Button>
            <Button onClick={()=>navigate('/verification/selfie')} >No</Button>
        </DialogActions>
    </Dialog>
</div>
  )
}

export default BvnVerification