import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import CashFlakesLogo from "../assets/logo1.png";
import { loginSchemas } from '../schemas';

import axios from 'axios';
import { useSelector } from 'react-redux';

const Login = () => {
    useEffect(()=>{
        document.title = 'CashFlakes | Login'
    }, [])

    const navigate = useNavigate();
    const uri = useSelector(state=>state.ApiReducer.uri)
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false)  
    const [passwordType, setPasswordType] = useState('password')
    const [token, setToken] = useState('')

    const { values, handleBlur, handleChange, errors, touched, handleSubmit } = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: loginSchemas, 
        onSubmit : ( values, actions) => {
            setError('')
            setIsLoading(true)
            // console.log(values)
            axios.post(`${uri}login`, 
                values,
                {headers:{
                    'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                    'Content-type': 'application/json',
                    'Accept': 'application/json'
                }}).then(res=>{
                if(res.data.statusCode === 200){
                    console.log(res.data.data)
                    sessionStorage.setItem('authUser', JSON.stringify(res.data.data))
                    navigate('/user/dashboard')
                }else{
                    setIsLoading(false)
                    console.log(res.data.data)
                    setToken(res.data.data.token)
                    setError(res.data.data.error)
                    // console.log(res.data.data.error.includes('activated'))
                }
            }).catch(err=>{
                setIsLoading(false)
                console.log(err)
                // setError(err.response.data.error)
                setError('Internal Server Error')
            })
        }
    });
    const verify = ()=>{
        sessionStorage.setItem('newUser', JSON.stringify({email: values.email, token}))
        navigate('/verification/otp')
    }

    
    return (
        <div>
            <div className='d-flex justify-content-between align-items-center'>
                <a href='/' className='navbar-brand text-dark font-weight-bold text-lowercase h3 animate__animated animate__slideInLeft animate__slow px-md-5 px-1 fs-9 pt-3' >
                    <img alt='logo' src={CashFlakesLogo} width='30px' className='my-0' /> <span className='text-capitalize mt-5'>CashFlakes</span>
                </a>
                <div>
                    <button className='btn nav-btn text-white font-weight-bold mx-1 px-5' onClick={ () => navigate('/auth/register')} >Register</button>
                </div>         
            </div>

            <div className='pt-5 my-md-2'>
                   
                {/* <div className='cashflakes-icon d-block d-md-none d-flex justify-content-center'  >
                    <img alt='logo' src={CashFlakesLogo} width='150px' height='150px' />
                </div> */}
                <h3 className='text-center form-titles fw-bolder'>Welcome Back</h3>
                {/* LOGIN FORM  */}
                <p className='text-center text-secondary'>Sign in with your Email address and valid password</p>
                <form className='px-0 px-md-5' onSubmit={handleSubmit}>
                    {   error !== ''
                       ?
                        <div className='alert alert-danger text-capitalize'>
                            <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error}</span>
                            <button onClick={verify} className={error.includes('activated') ? 'btn btn-danger px-4 fw-bold mx-1' : 'd-none'}>Verify</button>
                        </div>
                        :
                        ''
                    }

                    <div className='form-group mt-2'>
                        <label>Email Address</label>
                        <input className='form-control py-3'
                         placeholder='Enter your E-mail Address'
                         onChange={handleChange}
                         onBlur={handleBlur}
                         name='email'
                         value={values.email}
                         />
                        {errors.email && touched.email ? <div className='text-danger'>{errors.email}</div> : " "}
                    </div>
                    
                    
                    <div className='form-group mt-2'>
                        <label>Password</label>
                            <div className='input-group'>                                
                                <input className='form-control py-3 border-end-0'
                                placeholder='Enter your Password' 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                name='password'
                                value={values.password}
                                type={passwordType} 
                                />
                                <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> passwordType === 'text' ? setPasswordType('password') : setPasswordType('text')}>
                                    <i className={passwordType === 'text' ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                </span>
                            </div>
                            {errors.password && touched.password ? <div className='text-danger'>{errors.password}</div> : " "}
                    </div>
                
                <div className='d-grid'>
                    <button type='submit' className={isLoading ? 'btn disabled py-2 my-4 text-primary' : 'btn theme-bg-btn text-white font-weight-bold btn-block my-4 py-2' }>
                        {
                            isLoading
                            ?
                            'Please wait...'
                            :
                            'Sign In'
                        }
                    </button>
                </div>
                    <p className='pb-5 pt-2 text-center h6'>Forgot Password? <span className='theme-color cursor-pointer' onClick={()=>navigate('/forgotPword')} >Reset Here</span></p>
                </form>
            </div>
        </div>
    );
};

export default Login;