import React, { useEffect, useState } from 'react'
import CashFlakesLogo from "../assets/logo1.png";
import { useFormik } from 'formik';
import { otpSchemas } from '../schemas'
import OtpInput from 'react18-otp-input'
import { useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useSelector } from 'react-redux';
import { Backdrop, CircularProgress } from '@mui/material';

const Otp = () => {
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [otp, setOtp] = useState('')
    // const [otpObj, setOtpObj] = useState({otp: '', id: ''})
    const [newUser, setNewUser] = useState({email: ''})
    const [error, setError] = useState('')
    const [response, setResponse] = useState('')
    const uri = useSelector(state=>state.ApiReducer.uri)


    useEffect(()=>{
        document.title = 'CashFlakes | OTP Verification'
        setNewUser(JSON.parse(sessionStorage.getItem('newUser')))
    }, [])

    const resendOtp = ()=>{
        setOpen(true)
        // setNewUser(JSON.parse(sessionStorage.getItem('newUser')))
        setError('')
        axios.post(`${uri}auth/forgot_password`, 
        {email: newUser.email},
        {
            headers: {
                'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                'Content-type': 'application/json',
                'Accept': 'application/json'
            }
        }
        ).then((res)=>{
            const { token } = res.data.data
            let user = {...newUser, token: token }
            setNewUser(user)
            sessionStorage.setItem('newUser', JSON.stringify(user))
            console.log(token, user)       
          if(res.data.data.statusCode !== 200){
            setError(res.data.data.error)
            setOpen(false)
            setResponse('')
            setIsLoading(false)
          }else{
            setIsLoading(false)
            setOpen(false)            
            setResponse('Otp has been resent')
            setError('')            
          }
        }).catch((err)=>{
          console.log(err)
          setError(err.message)
          setResponse('')
          setOpen(false)
          setIsLoading(false)
        })
    }
    const handleOtpChange = (otp) => {
        setOtp(otp)        
    }
    const submitOtp = ()=>{
        if(otp.length === 6){
            setError('')
            setIsLoading(true)    
            setResponse('')
            const payload = { otp, id: newUser.id }        
            axios.post(
                `${uri}auth/otp`, 
                payload, 
                {
                    headers:{
                        'Authorization': `Bearer ${newUser.token}`,
                        'APIKEY': '4f1fe63a-5f8b-4e7f-ad38-e68445079351',
                        'Content-type': 'application/json',
                        'Accept': 'application/json'
                    }
                }).then((res)=>{
                    if(res.data.data.statusCode === 200){
                        sessionStorage.removeItem('newUser')
                        sessionStorage.setItem('bvnAuth', JSON.stringify(res.data.data))
                        navigate('/verification/bvn')
                    }else{
                        setError(res.data.data.error)
                        setIsLoading(false)
                    }
                }).catch((err)=>{
                    setIsLoading(false)
                    if (err.response.data !== undefined) {
                        setError(err.response.data.error)
                        console.log(err)
                    } else {
                        setError('Internal Server Error')
                        console.log(err)
                    }
                })
        }else{
            console.log('Input more')
        }
    }
  return (
    <div>
      <div className='d-flex justify-content-center mt-0 mt-md-5 px-2 px-md-4 px-lg-0'>
        <div className='col-lg-5 col-md-8'>
          <div className='px-0 py-5'>
              <div className='d-flex justify-content-center pb-4'>
                <img alt='logo' src={CashFlakesLogo} width='40px' height='40px' className='my-0' /> <span className='text-capitalize font-weight-bolder h6 mt-2'><b>CashFlakes</b></span>
              </div>

              <h3 className='text-center form-titles fw-bolder'>OTP Verification</h3>
              <p className='text-center text-secondary'>Enter the code sent to {newUser !== null ? newUser.email.split('@')[0].slice(0,7) + '*****' + newUser.email.split('@')[1] : navigate('/auth/register')}</p>
              {
                error !== ''
                &&
                <div className='alert alert-danger'>
                    <span><strong><i className='fa fa-exclamation-triangle'></i></strong> {error} </span>
                </div>
            }
            {
                response !== ''
                &&
                <div className='alert alert-success'>
                    <span><strong><i className='fa fa-check'></i></strong> {response} </span>
                </div>
            }
        
            <div className='px-0 d-flex justify-content-center'>
                <div className='col-md-9'>
                    <div className=''>
                        <OtpInput 
                            value={otp} 
                            onChange={handleOtpChange}                            
                            isInputNum={true} 
                            numInputs={6} 
                            separator={<span>-</span>} 
                            isDisabled={isLoading} 
                            inputStyle={'form-control fs-2 w-100'} 
                            containerStyle={'w-100'}
                            shouldAutoFocus={true}
                        />
                    </div>                    
                    <button onClick={submitOtp} type='submit' className={isLoading ? 'btn disabled text-primary d-block py-2 my-2 w-100' : 'btn btn-primary d-block py-2 my-2 w-100'} >
                        {
                            isLoading
                            ?
                            'Please wait...'
                            :
                            'Submit'
                        }
                    </button>
                    <p className='py-2 h6 text-center text-secondary'>Didn't get the code? <span className='theme-color cursor-pointer' onClick={resendOtp} >Resend</span></p>
                </div>              
            </div>
          </div>
        </div>
      </div>
        <Backdrop
            sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
        >
            <div>
                <p><span className='spinner-border'></span></p>
            </div>
        </Backdrop>
    </div>
  )
}

export default Otp;