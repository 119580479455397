import React from 'react'
import Webcam from 'react-webcam';

const MyWebCam = (props)=>{
    const { image } = props
    const screenShot = (screenshotBytes)=>{        
        image(screenshotBytes)
    }
    return (
        <div id='webcam' className='modal fade p-5'>
            <div className='modal-dialog modal-dialog-centered'>
                <div className='modal-content p-5 border-0 bg-modal-camera'>
                    <div className='position-relative d-flex justify-content-center'>
                        <Webcam
                            audio={false}
                            screenshotFormat='image/jpeg'
                            height={200}
                            width={200}
                            screenshotQuality={1}
                            className='rounded-circle'
                            >   
                            {({getScreenshot})=>(
                                <button data-dismiss='modal' onClick={()=>screenShot(getScreenshot({width: 350, height: 350}))} className='btn btn-primary fw-bolder photo-btn'>
                                    Snap
                                </button>
                            )}
                        </Webcam>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyWebCam