import React from "react";
import CashFlakesLogo from "../assets/logo1.png";
import Telegram from "../assets/images/telegram.svg";
import Discord from "../assets/images/discord.svg";
import Instagram from "../assets/images/instagram.png";
// import Medium from "../assets/images/medium.svg";
import Twitter from "../assets/images/twitter.svg";

const Footer = () => {
  return (
    <div className="container-fluid my-5">
      <div className="row mt-2">
        <div className="col-sm d-flex justify-content-md-start justify-content-center">
          <a
            href="/"
            className="navbar-brand text-dark font-weight-bold text-lowercase h3 animate__animated animate__slideInLeft animate__slow  fs-9 pt-3 px-4"
          >
            <img alt="logo" src={CashFlakesLogo} width="40px" />{" "}
            <span className="text-capitalize mt-5 fw-bold fs-6 ">
              CashFlakes
            </span>
          </a>
        </div>
        <div className="col-sm footerLinks">
          <ul className="list-unstyled text-muted">
            <li className="fw-bold p-1 section-medium-smalll"> Services</li>
            <li className="p-1 mt-2 section-small"> P2P Trading</li>
            <li className="p-1 section-small"> Utility Purchase</li>
            <li className="p-1 section-small"> Send and Recieve</li>
          </ul>
        </div>
        <div className="col-sm footerLinks">
          <ul className="list-unstyled text-muted">
            <li className="fw-bold p-1 section-medium-smalll">Learn</li>
            <li className="p-1 mt-2 section-small">Blog </li>
            <li className="p-1 section-small">About Us</li>
            <li className="p-1 section-small"> Help Center</li>
          </ul>
        </div>
        <div className="col-sm footerLinks">
          <ul className="list-unstyled text-muted">
            <li className="fw-bold p-1 section-medium-smalll">Legal</li>
            <li className="p-1 mt-2 section-small">Privacy Policy</li>
            <li className="p-1 section-small">Terms and Condition</li>
            <li className="p-1 section-small">Anti- Money Laundering Policy</li>
          </ul>
        </div>
        <div className="col-sm footerLinks">
          <ul className="list-unstyled text-muted pb-5">
            <li className="fw-bold p-1 section-medium-smalll">Contact</li>
            <li className="p-1 mt-2 section-small">support@cashflakes.com</li>
            <div className="pb-3 d-flex justify-content-md-start justify-content-center pt-1">
              <a className="px-2" href="https://t.me/cashflakes" target="_blank">
                <i className="fa fa-telegram fa-lg"></i>
              </a>
              <a className="px-2" href="https://twitter.com/Cashflakes_" target="_blank">
                <i className="fa fa-twitter fa-lg"></i>
              </a>
              <a className="px-2" href="https://Instagram.com/cashflakes_" target="_blank">
                <i className="fa fa-instagram fa-lg"></i>
              </a>
              <a className="px-2" href="https://discord.gg/2TfweBBF" target="_blank">
                <i className="fa fa-discord fa-lg"></i>
              </a>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
