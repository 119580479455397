import React, { useEffect } from 'react';

// images 
import MoneyIcon from "../assets/money.png";
import CryptoIcon from "../assets/crypto.png";
import SendCryptoIcon from "../assets/sendCrypto.png";

// aos 
import AOS from "aos";
import "aos/dist/aos.css";


const Uses = () => {
  useEffect(() =>{
    AOS.init({duration : 2000})
  }, [])

  return (
      <div className='p-4 animation w-100' data-aos="fade-up">
            <h3 className='text-capitalise fw-bold text-center py-3 sectionHeading'>What is CashFlakes used for?</h3>
          <div className="row">
            <div className="col-md">
              <div className="card mt-3 p-3 pointer use-card" >
                <div className="d-flex justify-content-center">
                  <img src={SendCryptoIcon} className="rounded-circle img-fluid w-25  " alt="..." />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title text-capitalize fw-bold section-medium">Send & Recieve Payments</h5>
                  <p className="card-paragraph card-text text-secondary section-small">
                  Purchase goods and services directly from your wallet to any payment reciever. 
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md">
              <div className="card mt-3 p-3 pointer use-card" >
                <div className="d-flex justify-content-center">
                  <img src={CryptoIcon} className="rounded-circle img-fluid w-25  " alt="..." />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title text-capitalize fw-bold section-medium">Purchase Utilities</h5>
                  <p className="card-paragraph card-text text-secondary section-small">
                  With CFT token you can purchase internet data, airtime, pay electricity and cable TV bills/subscriptions directly from your wallet. 
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md">
              <div className="card mt-3 p-3 pointer use-card" >
                <div className="d-flex justify-content-center">
                  <img src={MoneyIcon} className="rounded-circle img-fluid w-25  " alt="..." />
                </div>
                <div className="card-body text-center">
                  <h5 className="card-title text-capitalize fw-bold section-medium">P2P Trading</h5>
                  <p className="card-paragraph card-text text-secondary section-small">
                    Purchase and sell tokens in the Cashflakes open markets. 
                  </p>
                </div>
              </div>
            </div>

          </div>
      </div>
  )
}

export default Uses
