import React, { useState } from 'react';

const UpdatePassword = ()=>{
    const [currentPwordType, setCurrentPwordType] = useState('text')
    const [newPwordType, setNewPwordType] = useState('text')
    const [confirmPwordType, setConfirmPwordType] = useState('text')
    return (
        <>
            <form className='my-3'>
                <div className='row my-3'>
                    <div className='col-md-3'>
                        <p className='text-muted'>Current Password</p>
                    </div>
                    <div className='col-md-5'>
                        <div className='input-group'>
                            <input type={currentPwordType} className='form-control form-control-lg rounded-0 border-end-0' />
                            <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> currentPwordType === 'text' ? setCurrentPwordType('password') : setCurrentPwordType('text')}>
                                <i className={currentPwordType === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row my-3'>
                    <div className='col-md-3'>
                        <p className='text-muted'>New Password</p>
                    </div>
                    <div className='col-md-5'>
                        <div className='input-group'>
                            <input type={newPwordType} className='form-control form-control-lg rounded-0 border-end-0' />
                            <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> newPwordType === 'text' ? setNewPwordType('password') : setNewPwordType('text')}>
                                <i className={newPwordType === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='row my-3'>
                    <div className='col-md-3'>
                        <p className='text-muted'>Confirm New Password</p>
                    </div>
                    <div className='col-md-5'>
                        <div className='input-group'>
                            <input type={confirmPwordType} className='form-control form-control-lg rounded-0 border-end-0' />
                            <span className='input-group-text rounded-0 bg-white border-start-0 cursor-pointer' onClick={()=> confirmPwordType === 'text' ? setConfirmPwordType('password') : setConfirmPwordType('text')}>
                                <i className={confirmPwordType === 'text' ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-end col-md-8'>
                    <button className='btn btn-primary rounded-0'>Save Changes</button>
                </div>
            </form>
        </>
    )
}

export default UpdatePassword