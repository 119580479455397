import React, { useEffect, useState } from 'react'
import ProfileAvatar from "../assets/images/56.svg";
import { useFormik } from 'formik';

const General = ()=>{
    const user = JSON.parse(sessionStorage.getItem("authUser"));
    const [isEditting, setIsEditting] = useState(false)

    const {handleBlur, handleChange, handleSubmit, errors, values, touched} = useFormik({
        initialValues: {
            fullname: user.surname + ' ' + user.name,
            email: user.email,
            phone: user.phone
        },
        onSubmit: (values)=>{}
    })

    const editProfile = ()=>{
        document.getElementById('name').focus()
        setIsEditting(true)
    }
   
    return (
        <div>
            <div className='row w-100 mx-0 my-3'>
                <div className='col-md-3'>
                    <p className='text-muted py-0 my-0'>Profile Photo</p>
                    <p className='my-0 pt-0'>Update your profile photo from here</p>
                </div>
                <div className='col-md-5'>
                    <div className='d-flex justify-content-between'>
                        <div className='bg-photo border p-1 rounded-circle'>
                            <img className="profileAvatar rounded-circle" src={!user.selfie ? ProfileAvatar : user.selfie} alt="Profile Avatar" height={'60px'} />
                        </div>
                        <div>
                            <span className='px-2 cursor-pointer'>Delete</span>
                            <span className='px-2 cursor-pointer text-primary'>Update</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row w-100 mx-0 my-2'>
                <div className='col-md-3'>
                    <p className='text-muted h6'>Full Name</p>
                </div>
                <div className='col-md-5'>
                    <input id='name' disabled={!isEditting} name='fullname' onChange={handleChange} className='form-control rounded-0 form-control-lg' value={values.fullname} />
                </div>
            </div>
            <div className='row w-100 mx-0 my-2'>
                <div className='col-md-3'>
                    <p className='text-muted h6'>Email Address</p>
                </div>
                <div className='col-md-5'>
                    <input disabled={!isEditting} name='email' onChange={handleChange} className='form-control rounded-0 form-control-lg' value={values.email} />
                </div>
            </div>
            <div className='row w-100 mx-0 my-2'>
                <div className='col-md-3'>
                    <p className='text-muted h6'>Phone Number</p>
                </div>
                <div className='col-md-5'>
                    <input disabled={!isEditting} name='phone' onChange={handleChange} className='form-control rounded-0 form-control-lg' value={values.phone} />
                </div>
            </div>
            <div className='row w-100 mx-0'>
                <div className='col-md-3'></div>
                <div className='col-md-5'>
                    <div className='d-flex justify-content-end'>
                        <button onClick={editProfile} className='btn btn-secondary mx-1 rounded-0'><i className='fa fa-edit'></i> Edit Profile</button>
                        <button className='btn btn-primary mx-1 rounded-0'>Save Changes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default General